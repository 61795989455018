<div class="modal-header shadow-sm">
    <h6 class="modal-title" id="exampleModalLabel">
        {{ title }}
    </h6>
    <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
</div>
<div class="modal-body scrollbar-primary thin">
    <form [formGroup]="uploadForm">
        <app-select [form]="uploadForm" [control]="uploadForm.controls['organizationId']"
            [controlName]="'organizationId'" [filter]="true" [data]="organizations" [name]="'label'" [value]="'value'"
            [placeholder]="'Select Organization'" (emitSelectedValue)="receiveSelectedValue($event)" class="select-outline mx-2">
        </app-select>
        <mdb-file-upload (fileAdded)="onFileAdd($event)" (fileRemoved)="onFileRemove()"></mdb-file-upload>
    </form>
</div>
<div class="modal-footer pt-0 d-flex justify-content-between">
    <button type="button" class="btn btn-gray-light" (click)="modalRef.close()">Cancel</button>
    <button type="button" class="btn btn-primary" [disabled]="!uploadForm.valid || isLoading.upload" (click)="onUpload()"><span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"
        *ngIf="isLoading.upload"></span>Upload</button>
</div>