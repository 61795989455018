import { Injectable } from '@angular/core';
import { map, of } from 'rxjs';
import { API_CONSTANTS } from '../constants';
import { HttpClientService } from './http-client.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: HttpClientService
  ) { }

  getAllEndUsers(query?: any) {
    return this.http.get(API_CONSTANTS.END_USERS, query)
    .pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  getUserById(userId: string) {
    return this.http.get(API_CONSTANTS.END_USERS + `/${userId}`)
    .pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  getUserActivities(userId: string, params?: any) {
    return this.http.get(API_CONSTANTS.END_USERS + `/${userId}${API_CONSTANTS.ACTIVITIES}`, params)
    .pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  create(user: any) {
    return this.http.post(API_CONSTANTS.END_USERS, user)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  update(user: any) {
    return this.http.put(API_CONSTANTS.END_USERS + `/${user.id}`, user)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  delete(userId: string) {
    return this.http.remove(API_CONSTANTS.END_USERS + `/${userId}`)
    .pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  linkDevice(data: any) {
    return this.http.patch(`${API_CONSTANTS.END_USERS}${API_CONSTANTS.LINK_DEVICE}`, data)
    .pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  unLinkDevice(data: any) {
    return this.http.patch(`${API_CONSTANTS.END_USERS}${API_CONSTANTS.UNLINK_DEVICE}`, data)
    .pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  updateConfigurations(data: any) {
    return this.http.patch(`${API_CONSTANTS.END_USERS}/${data.id}${API_CONSTANTS.CONFIGURATIONS}`, data.configurations).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getLocations(userId: string) {
    return this.http.get(`${API_CONSTANTS.END_USERS}/${userId}/last-locations`)
      .pipe(
        map((response: any) => {
          return response;
        })
      )
  }

  getNotes(id: string){
    return this.http.get(`${API_CONSTANTS.END_USERS}${API_CONSTANTS.NOTES}/${id}`)
      .pipe(
        map((response: any) => {
          return response;
        })
      )
  }

  addNote(note: any) {
    return this.http.post(`${API_CONSTANTS.END_USERS}${API_CONSTANTS.NOTES}`, note)
      .pipe(
        map((response: any) => {
          return response;
        })
      )
  }

  deleteNote(id: string) {
    return this.http.remove(`${API_CONSTANTS.END_USERS}${API_CONSTANTS.NOTES}/${id}`)
    .pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  updateNote(note: any, id: string) {
    return this.http.put(`${API_CONSTANTS.END_USERS}${API_CONSTANTS.NOTES}/${id}`, note)
      .pipe(
        map((response: any) => {
          return response;
        })
      )
  }

  removeCadence(id: string) {
    return this.http.put(`${API_CONSTANTS.END_USERS}/remove-emergency/${id}`, null)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  syncAssociatedPhoneInRapid(id: string) {
    return this.http.post(`${API_CONSTANTS.END_USERS}/sync-phone-in-rapid/${id}`, null)
      .pipe(
        map((response: any) => {
          return response;
        })
      )
  }

  syncAccountNumberInRapid(id: string) {
    return this.http.post(`${API_CONSTANTS.END_USERS}/add-in-rapid/${id}`, null)
      .pipe(
        map((response: any) => {
          return response;
        })
      )
  }
}
