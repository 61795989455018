    <google-map [height]="height"
        [width]="width"
        [center]="center"
        [zoom]="zoom"
        >
        <map-marker *ngFor="let marker of markers"
            [position]="marker.position"
            [icon]="marker.icon"
            >
        </map-marker>
        <map-polyline [options]="polylineOptions" [path]="vertices"></map-polyline>
    </google-map>
