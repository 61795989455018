import { Component, ContentChild, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { finalize } from 'rxjs';
import { NIGHTWEB_APP_CONSTANT, NODATA_MESSAGES, STATUS_CODES } from 'src/app/constants';
import { DataService, DeviceService, UserService } from 'src/app/services';
import { NightwebUtil } from 'src/app/utils';
import { InputType, TableConfig } from '../../common/interface';
import { ConfigurationModalComponent } from '../configuration-modal/configuration-modal.component';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { AddEditUserModalComponent } from '../add-edit-user-modal/add-edit-user-modal.component';
import { EndUserComponent } from '../../end-user/end-user.component';

@Component({
  selector: 'app-link-device-modal',
  templateUrl: './link-device-modal.component.html',
  styleUrls: ['./link-device-modal.component.scss']
})
export class LinkDeviceModalComponent implements OnInit {

  linkDeviceForm!: FormGroup;

  loading = false;
  submitted = false;
  title!: string;
  formData: any = {};
  selectedDeviceId: string = '';
  searchKeyword: string = '';

  config = NIGHTWEB_APP_CONSTANT.modalConfig;
  configurationModalConfig!: MdbModalRef<ConfigurationModalComponent>;
  confirmationModalRef!: MdbModalRef<ConfirmationModalComponent>;
  userAddEditComponent!: MdbModalRef<AddEditUserModalComponent>;
  status_codes = STATUS_CODES;
  notFoundMessage = NODATA_MESSAGES.NO_DEVICES;
  tableConfig: TableConfig = {
    headerElements: [
      'Device', 'Serial No.', 'Phone', 'IMEI'
    ],
    bodyElements: [
      { value: 'name', addonType: InputType.CHECKBOX },
      { value: 'serialNumber' },
      { value: 'associatedPhone', dataType: 'phone' },
      { value: 'imeiNumber' }
    ],
    tableDatas: [],
    actionItems: []
  }
  tableHeadClass: string = 'bg-white text-gray-dark';

  constructor(
    private formBuilder: FormBuilder,
    private nightWebUtil: NightwebUtil,
    private deviceService: DeviceService,
    private modalService: MdbModalService,
    public modalRef: MdbModalRef<LinkDeviceModalComponent>,
    private dataService: DataService,
    private _userService: UserService
  ) {
    this.setLinkDeviceForm();
   }

  ngOnInit(): void {
    this.getAllActiveDevices();
  }

  setLinkDeviceForm() {
    this.linkDeviceForm = this.formBuilder.group({
      keyword: [''],
      limit: [NIGHTWEB_APP_CONSTANT.defaultLimit],
      offset: []
    });
  }

  getAllActiveDevices(filter?: any) {
    this.loading = true;
    this.tableConfig.tableDatas = [];
    this.deviceService.getAllDevices({ ...filter, activeUser: null, organizationId: this.formData?.organizationId })
      .pipe(finalize(() => this.loading = false))
      .subscribe({
        next: (response) => {
          this.tableConfig.tableDatas = response.data.rows;
        },
        error: (e) => {
        }
      })
  }

  setupConfiguration() {
    const { advocateId, safeWord, accountNumber, userId } = this.formData;
    if (advocateId && safeWord && accountNumber) {
      const config = this.nightWebUtil.shallowCopy(this.config);
      config.data.title = 'Configuration';
      config.modalClass = 'modal-dialog-scrollable modal-md';
      config.data.formData = this.formData;
      config.data.confirmButtonLabel = 'Link Device';
      config.data.formData.module = 'Device';
      config.data.formData.module = 'organization';
      config.data.formData.mode = 'add';
      config.data.formData.deviceId = this.selectedDeviceId;
      !userId && (config.data.formData.userId = this.formData.id);
      this.modalRef.close();
      this.configurationModalConfig = this.modalService.open(ConfigurationModalComponent, config);
      this.configurationModalConfig.onClose.subscribe((data: any) => {
        // if (data) {
          this.dataService.sendData(data);
        // }
      });
    } else {
      const config = this.nightWebUtil.shallowCopy(this.config);
      config.data.confirmButtonLabel = 'Update End Fields';
      config.data.formData = this.formData;
      config.modalClass = 'modal-md';
      config.data.title = 'Update End User';
      const text = !advocateId && !safeWord && !accountNumber ? '<strong>Advocate</strong>, <strong>Safe Word</strong> and <strong>Account Number</strong>' 
        : !advocateId && !accountNumber ? '<strong>Advocate</strong> and <strong>Account Number</strong>' 
        : !advocateId && !safeWord ? '<strong>Advocate</strong> and <strong>Safe Word</strong>'
        : !safeWord && !accountNumber ? '<strong>Safe Word</strong> and <strong>Account Number</strong>'
        : !advocateId ? '<strong>Advocate</strong>'
        : !safeWord ? '<strong>Safe Word</strong>'
        : 'Account Number'
      config.data.body = `The device cannot be linked with the user until the following required fields are updated: ${text} to <strong>${this.formData?.demographic?.firstName + ' ' + this.formData?.demographic?.middleName + ' ' + this.formData?.demographic?.lastName}</strong>. Please update these fields before proceeding.`;
      this.confirmationModalRef = this.modalService.open(ConfirmationModalComponent, config);
      this.confirmationModalRef.onClose.subscribe((data: any) => {
        if (data) {
          this.getEndUser(data.id);
        }
      });
    }
  }

  getEndUser(id: string) {
    this._userService.getUserById(id)
      .pipe()
      .subscribe({
        next: response => {
          this.openAddEditUserModal(response.data);
        },
        error: error => console.log('error', error)
      })
  }

  openAddEditUserModal(user: any) {
    const config = this.nightWebUtil.shallowCopy(this.config);
    config.data.title = 'Edit';
    config.modalClass = 'modal-dialog-scrollable modal-md';
    config.data.formData = user;
    this.userAddEditComponent = this.modalService.open(AddEditUserModalComponent, config);
    this.userAddEditComponent.onClose.subscribe((data: any) => {
      if (data) {
        this.formData = data.updatedData;
      };
    });
  }

  receiveSearchKeyword(keyword: any) {
    this.searchKeyword = keyword;
    this.linkDeviceForm.patchValue({ keyword });
    const filterParams = this.nightWebUtil.getFilterParams(this.linkDeviceForm.value)
    this.getAllActiveDevices(filterParams);
  }

  receiveEvent({ action, event: { target: { checked } }, object: { id } }: any) {
    if (action === 'change') {
      if (checked) {
        this.selectedDeviceId = id;
      } else {
        this.selectedDeviceId = '';
      }
    }
  }

  closeModal() {
    this.modalRef.close(this.formData);
  }
}
