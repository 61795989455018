<div [class]="tableWrapperClass" #tableElement (scroll)="!loading && onScroll($event)">
    <table class="table table-striped">
            <thead [class]="tableHeadClass" style="z-index: 999;">
                <tr>
                    <th *ngFor="let head of tableConfig?.headerElements">{{ head }}</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngIf="!loading; then isLoaded; else notLoaded"></ng-container>
                <ng-template #isLoaded>
                    <tr *ngFor="let data of tableConfig?.tableDatas; let i = index;" class="text-nowrap">
                        <td [class.cursor-pointer]="field?.navigation" *ngFor="let field of tableConfig?.bodyElements"
                            (click)="navigate(field, data)" [style.width]="field.dataType === 'checkbox' && '55px'">
                            <ng-template [ngIf]="field?.addonType" [ngIfElse]="textOnly">
                                <ng-container [ngSwitch]="field?.addonType === inputType.CHECKBOX">
                                    <div class="form-check">
                                        <input type="{{field?.addonType}}" class="form-check-input"
                                            id="{{field?.value}}-{{i}}" [checked]="currentCheckedIndex === i"
                                            (change)="change($event, data, i)" />
                                        <label class="form-check-label" for="{{field?.value}}-{{i}}">{{
                                            getValueByField(field, data) }}</label>
                                    </div>
                                </ng-container>
                            </ng-template>
                            <ng-template #textOnly>
                                <ng-container [ngSwitch]="field.dataType">
                                    <div *ngSwitchCase="'checkbox'">
                                        <div *ngIf="!data.isProcessing; else processingSpinner">
                                            <input type="checkbox" [disabled]="isProcessing" (change)="performAction(data, 'checkbox', $event)" mdbCheckbox class="form-check-input"/>
                                        </div>
                                        <ng-template #processingSpinner>
                                            <div class="spinner-border text-primary" style="width: 20px; height: 20px; font-size: 12px" role="status"></div>
                                        </ng-template>
                                    </div>
                                    <div *ngSwitchCase="'boolean'">
                                        <div [innerHTML]="getValueByField(field, data)"></div>
                                    </div>
                                    <div *ngSwitchCase="'address'">
                                        <div [innerHTML]="getValueByField(field, data)"></div>
                                    </div>
                                    <div *ngSwitchCase="'contact'">
                                        <div [innerHTML]="getValueByField(field, data)"></div>
                                    </div>
                                    <div *ngSwitchCase="'device'">
                                        <div [innerHTML]="getValueByField(field, data)"></div>
                                    </div>
                                    <div *ngSwitchCase="'updated'">
                                        <div [innerHTML]="getValueByField(field, data)"></div>
                                    </div>
                                    <div *ngSwitchCase="'status'">
                                        <div [innerHTML]="getValueByField(field, data)"></div>
                                    </div>
                                    <div *ngSwitchCase="'created'">
                                        <div [innerHTML]="getValueByField(field, data)"></div>
                                    </div>
                                    <div *ngSwitchCase="'module'">
                                        <div [innerHTML]="getValueByField(field, data)"></div>
                                    </div>
                                    <div *ngSwitchCase="'eventType'">
                                        <div [innerHTML]="getValueByField(field, data)"></div>
                                    </div>
                                    <div *ngSwitchCase="'adminDemographic'">
                                        <div [innerHTML]="getValueByField(field, data)"></div>
                                    </div>
                                    <div *ngSwitchDefault>
                                        {{ getValueByField(field, data) }}
                                    </div>
                                </ng-container>
                                <!-- {{ getValueByField(field, data) }} -->
                            </ng-template>
                            <div *ngIf="field?.actionButton && tableConfig?.actionItems?.length">
                                <div mdbDropdown class="dropdown">
                                    <a role="button" id="dropdownMenuButton" aria-expanded="false" mdbDropdownToggle>
                                        <i-bs name="three-dots-vertical"></i-bs>
                                    </a>
                                    <ul mdbDropdownMenu class="dropdown-menu dropdown-menu-end"
                                        aria-labelledby="dropdownMenuButton">
                                        <ng-container *ngFor="let item of tableConfig?.actionItems">
                                            <ng-container *ngIf="(data?.superRoleId && item.eventName !== 'deleteModule') || !data.superRoleId">
                                                <li *ngIf="item?.name ? item.name : getActionLabel(item, data)"><a
                                                    class="dropdown-item" role="button"
                                                    (click)="performAction(data, item?.eventName)">
                                                    {{item?.name ? item.name : getActionLabel(item, data)}}
                                                    </a>
                                                </li>
                                            </ng-container>
                                        </ng-container>
                                    </ul>
                                </div>
                            </div>
                        </td>
                    </tr>
                </ng-template>
                <ng-template #notLoaded>
                    <!-- <div class="pb-2" *ngIf="loading">
                        <app-loader [loader]="loading"></app-loader>
                    </div> -->
                </ng-template>

            </tbody>
    </table>
</div>

<div class="pt-3 pb-4 mb-4 text-center" *ngIf="!loading && tableConfig && tableConfig.tableDatas.length == 0">
    <i-bs name="journal-text" width="2rem" height="2rem"></i-bs>
    <p class="mt-2">{{notFoundMessage}}</p>
</div>

<div class="mt-3" *ngIf="(loading && tableConfig && tableConfig.tableDatas.length == 0)">
    <app-loader [loader]="loading"></app-loader>
</div>