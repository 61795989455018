import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { io, Socket } from 'socket.io-client';
import { environment } from '../../environments/environment';
import { HttpClientService } from './http-client.service';
import { API_CONSTANTS } from '../constants';
import { map } from 'rxjs';
import { NightwebUtil } from '../utils';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  socket!: Socket;
  message$: BehaviorSubject<any> = new BehaviorSubject(null);
  API_BASE_URL = environment.notificationUrl;
  private notificationCountSubject = new BehaviorSubject<number>(0);
  notificationCount$ = this.notificationCountSubject.asObservable();
  private eventSubjects: { [key: string]: Subject<any> } = {};

  constructor(
    private _http: HttpClientService,
    private nightwebUtil: NightwebUtil
  ) {
    if (!this.socket) {
      const user = this.nightwebUtil.decodeToken(
        this.nightwebUtil.getLoggedInUser()
      );

      this.socket = io(this.API_BASE_URL, {
        secure: false,
        reconnection: true,
        reconnectionAttempts: 3,
        extraHeaders: {
          auth: user.id,
        },
      });
      this.socket.emit('user-info', { id: user.id });
      this.socket.on('disconnect', () => {
        console.log('client disconnected');
      });
    }
    this.fetchNotification();
  }

  fetchNotification() {
    return new Observable((subscriber) => {
      this.socket.on('notification', (data) => {
        subscriber.next(data);
      });
    });
  }

  listen(eventName: string): Observable<any> {
    if (!this.eventSubjects[eventName]) {
      this.eventSubjects[eventName] = new Subject<any>();
      this.socket.on(eventName, (data) => {
        this.eventSubjects[eventName].next(data); // Notify the corresponding Subject with the received data
      });
    };
    return this.eventSubjects[eventName].asObservable();
  }

  getNotifications(query: any) {
    return this._http.get(API_CONSTANTS.NOTIFICATION, query).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  updateNotification(body: any) {
    return this._http
      .put(`${API_CONSTANTS.NOTIFICATION}/${body.id}`, body)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  updateUnreadNotificationCount(count: number) {
    this.notificationCountSubject.next(count);
  }

  saveFollowup(id:string, body: any) {
    return this._http
      .post(`${API_CONSTANTS.NOTIFICATION}/add-followup/${id}`, body)
      .pipe(
        map((response: any) => {
          return response;
        })
      )
  }

  updateFollowup(id: string, body: any) {
    return this._http
      .put(`${API_CONSTANTS.NOTIFICATION}/edit-followup/${id}`, body)
      .pipe(
        map((response: any) => {
          return response;
        })
      )
  }

  markAllAsRead() {
    return this._http
      .put(`${API_CONSTANTS.NOTIFICATION}/mark-all-as-read`,'')
      .pipe(
        map((response: any) => {
          return response;
        })
      )
  }
}
