import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { finalize } from 'rxjs';
import {
  MESSAGE_CONSTANTS,
  NIGHTWEB_APP_CONSTANT,
  NOTIFICATIONS,
  STATUS_CODES,
  TEXT_EDITOR,
} from '../../constants';
import { NotificationService, ToasterService } from '../../services';
import { DatePipe } from '@angular/common';
import { MdbDatepickerComponent } from 'mdb-angular-ui-kit/datepicker';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css'],
})
export class NotificationsComponent implements OnInit {
  @Input() maxHeight: string = '70vh';
  @Input() width: string = '65%';
  @Input() isNotificationPage: boolean = true;
  @Input() from: string = 'page';

  filters: any[] = NOTIFICATIONS.filters;
  isLoading = {
    notifications: false,
  };
  notifications: any = {
    todayNotifications: [],
    yesterdayNotifications: [],
    olderNotifications: [],
    count: 0,
    unreadCount: 0,
  };
  shortDateTimeFormat = NIGHTWEB_APP_CONSTANT.shortDateTimeFormat;
  queryParams: any = {
    limit: NIGHTWEB_APP_CONSTANT.defaultLimit,
    offset: NIGHTWEB_APP_CONSTANT.defaultOffset,
    keyword: '',
  };
  notificationList: any = {
    count: 0,
    unreadCount: 0,
    rows: [],
  };
  selectedFilter!: string;
  mdbDateFormat = NIGHTWEB_APP_CONSTANT.mdbDefaultDateFormat;
  editorConfig = TEXT_EDITOR;
  defaultDateTimeFormat = NIGHTWEB_APP_CONSTANT.defaultDateTimeFormat;
  defaultTime: string = '10:00 AM';
  tempNotificationStorage: any;
  todayDate = new Date();
  tomorrowDate = new Date(this.todayDate.getTime() + (24 * 60 * 60 * 1000));
  @ViewChild('datepicker', { static: false }) datepicker!: MdbDatepickerComponent;
  @ViewChild('notificationList', { static: false })
  private _notificationList!: ElementRef;
  datepickerOpened: boolean = false;
  timepickerOpened: boolean = false;
  notificationsForm!: FormGroup;

  @Output() onPickerSelected = new EventEmitter<any>();

  @HostListener('window:scroll', ['$event'])
  onScroll(event: any): void {
    if (this._notificationList) {
      const nativeElement = this._notificationList.nativeElement;
      const height = Math.round(
        event.target.clientHeight + nativeElement.scrollTop + 1
      );
      if (height >= nativeElement.scrollHeight) {
        this.onScrollToBottom();
      }
    }
  }

  constructor(
    private _router: Router,
    private _notificationService: NotificationService,
    private _toasterService: ToasterService,
    private _datePipe: DatePipe,
    private _formBuilder: FormBuilder
  ) {
    this.setNotificationsForm();
  }

  ngOnInit(): void {
    this.onFilterSelected(this.filters[0]);
    this.listenNotifications();
  }

  setNotificationsForm() {
    this.notificationsForm = this._formBuilder.group({
      filter: [this.filters[0].value]
    });
  }

  onScrollToBottom() {
    this.queryParams.offset += 1;
    this.notificationList.count !== this.notificationList.rows.length &&
      this.getNotifications(this.queryParams, false);
  }

  getNotifications(queryParams: any, notLazyLoaded: boolean = true) {
    this.queryParams.from = this.from;
    this.isLoading.notifications = notLazyLoaded;
    this._notificationService
      .getNotifications(queryParams)
      .pipe(finalize(() => this.isLoading.notifications = false))
      .subscribe({
        next: (response) => {
          if (response.statusCode == STATUS_CODES.OK && response.data.rows) {
            this.notificationList = {
              count: response.data.count,
              unreadCount: response.data.new,
              rows: [...this.notificationList.rows, ...response.data.rows],
            };
            this.categorizeNotifications(this.notificationList);
          }
        },
        error: (error) => {
          console.log('error fetching notifications', error);
          this._toasterService.showError(error);
        },
      });
  }

  /**
   * Categorizes notifications based on date
   * @param notifications
   * @returns
   */
  categorizeNotifications(data: any) {
    data.rows = data.rows.map((datum: any) => {
      if (datum.followUpDetails) {
        const followUpDetails = {
          ...datum.followUpDetails,
          createdAt: moment(new Date(datum.createdAt)).format(),
          updatedAt: moment(new Date(datum.updatedAt)).format(),
        };
        return {
          ...datum,
          updatedAt: moment(new Date(datum.updatedAt)).format(),
          followUpDetails,
        };
      } else {
        return {
          ...datum,
          updatedAt: moment(new Date(datum.updatedAt)).format(),
        };
      }
    });
    const today = moment(new Date()).format('YYYYMMDD');
    const yesterday = moment(
      new Date().setDate(new Date().getDate() - 1)
    ).format('YYYYMMDD');
    const todayNotifications = data.rows.filter(
      (notification: any) =>
        moment(notification.createdAt).format('YYYYMMDD') >= today
    );
    const yesterdayNotifications = data.rows.filter(
      (notification: any) =>
        moment(notification.createdAt).format('YYYYMMDD') < today &&
        moment(notification.createdAt).format('YYYYMMDD') >= yesterday
    );
    const olderNotifications = data.rows.filter(
      (notification: any) =>
        moment(notification.createdAt).format('YYYYMMDD') < yesterday
    );
    this.notifications = {
      todayNotifications,
      yesterdayNotifications,
      olderNotifications,
      count: data.count,
      unreadCount: data.unreadCount,
    };
    this.tempNotificationStorage = JSON.parse(
      JSON.stringify(this.notifications)
    );
    this.updateUnreadNotificationCount(this.notifications.unreadCount);
  }

  onFilterSelected(filter: any) {
    const { value } = filter;
    this.notificationList = {
      count: 0,
      unreadCount: 0,
      rows: [],
    };
    this.queryParams = {
      ...this.queryParams,
      offset: NIGHTWEB_APP_CONSTANT.defaultOffset,
      keyword: value,
    };

    if (this.isNotificationPage) {
      const lastSelectedFilterIndex = this.filters.findIndex(
        ({ isSelected }) => isSelected
       );
       this.filters[lastSelectedFilterIndex].isSelected = false;
       const selectedFilterIndex = this.filters.findIndex(
         (filter) => filter.value === value
       );
       this.filters[selectedFilterIndex].isSelected = true;
    };
    this.selectedFilter = filter.value;
    this.getNotifications(this.queryParams);
  }

  onNotificationSelected(notification: any, type: string, redirect: boolean = true) {
    redirect && this._router.navigate([notification.notice.link]);
    if(notification.status === 'new') {
      this._notificationService
      .updateNotification({ id: notification._id, type: 'viewed' })
      .subscribe({
        next: (response) => {
          if ((response.statusCode = STATUS_CODES.OK)) {
            const index = this.notifications[type].findIndex(
              (x: any) => x._id === notification._id
            );
            this.notifications.unreadCount -= 1;
            this.notifications[type][index].status = 'viewed';
            this.selectedFilter === 'unread' && this.notifications[type].splice(index, 1);
            this.updateUnreadNotificationCount(this.notifications.unreadCount);
          }
        },
        error: (error) => {
          console.log('error updating notification', error);
          this._toasterService.showError(error);
        },
      });
    };
  }

  listenNotifications() {
    this._notificationService.listen('new-notification').subscribe({
      next: (response: any) => {
        if (response && Object.keys(response).length > 1) {
          const notificationExists = this.notifications.todayNotifications.find(
            (notification: any) => notification._id === response._id
          );
          if (!notificationExists) {
            this.notifications.todayNotifications.unshift(response);
            this.notifications.unreadCount += 1;
            this.updateUnreadNotificationCount(this.notifications.unreadCount);
          }
        }
      },
      error: (error) => console.log('websocket error', error),
    });
  }

  updateUnreadNotificationCount(newCount: number) {
    this._notificationService.updateUnreadNotificationCount(newCount);
  }

  onFollowupActionsTaken(event: Event, index: number, type: string, action: string, notification?: any) {
    event.stopPropagation();
    switch (action) {
      case 'followup':
        this.notifications[type][index].addFollowup = true;
        if (!this.notifications[type][index].followUpDetails) {
          this.tempNotificationStorage[type][index].followUpDetails = {
            note: '',
            date: '',
            time: '',
          };
          this.notifications[type][index].followUpDetails = {
            note: '',
            date: '',
            time: '',
          };
        }
        break;
      case 'edit':
        const dateTimeString = this._datePipe.transform(
          this.notifications[type][index].followUpDetails.dateTime,
          'medium',
          'local'
        );
        if (dateTimeString) {
          const parts = dateTimeString.split('T');
          const newTime = new Date(
            this.notifications[type][index].followUpDetails.dateTime
          );
          const hours = newTime.getHours();
          const minutes = newTime.getMinutes();
          const period = hours >= 12 ? 'PM' : 'AM';
          const formattedTime = `${(hours % 12 || 12).toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${period}`;
          this.notifications[type][index].followUpDetails.date = new Date(
            parts[0]
          );
          this.tempNotificationStorage[type][index].followUpDetails.date =
            new Date(parts[0]);
          this.notifications[type][index].followUpDetails.time = formattedTime;
          this.notifications[type][index].addFollowup = true;
        }
        break;
      case 'save':
        this.notifications[type][index].savingFollowUp = true;
        const { date, note, time } = this.tempNotificationStorage[type][index].followUpDetails;
        const datePickerValue = date ? moment(date).format('YYYY-MM-DD') : moment(this.tomorrowDate).format('YYYY-MM-DD');
        const [dateYear, dateMonth, dateDay] = datePickerValue
          .split('-')
          .map(Number);
        const timeParts = time ? time.match(/(\d+):(\d+) (AM|PM)/) : this.defaultTime.match(/(\d+):(\d+) (AM|PM)/);
        let timeHour = parseInt(timeParts[1]);
        const timeMinute = parseInt(timeParts[2]);
        const isPM = timeParts[3] === 'PM';
        if (isPM && timeHour < 12) {
          timeHour += 12;
        }
        const combinedDate = new Date(
          dateYear,
          dateMonth - 1,
          dateDay,
          timeHour,
          timeMinute
        );
        const dateTime = moment(combinedDate).utc();
        const body = {
          dateTime,
          note,
        };
        const apiCall =
          this.notifications[type][index].status === 'followupRequired'
            ? 'updateFollowup'
            : 'saveFollowup';
        this._notificationService[apiCall](
          this.tempNotificationStorage[type][index]._id,
          body
        )
          .pipe(finalize(() => {
              delete this.notifications[type][index].savingFollowUp;
              delete this.notifications[type][index].addFollowup;
            }))
          .subscribe({
            next: (response: any) => {
              if (response && response.statusCode == STATUS_CODES.OK) {
                const followUpDetails = {
                  ...response.data.updatedData.followUpDetails,
                  updatedAt: moment(new Date(response.data.updatedData.followUpDetails.updatedAt)).format(),
                };
                this.notifications[type][index].followUpDetails = followUpDetails;
                this.notifications[type][index].status === 'followupRequired' ? this._toasterService.show('Success', MESSAGE_CONSTANTS.FOLLOWUP_UPDATED) : this._toasterService.show('Success', MESSAGE_CONSTANTS.FOLLOWUP_ADDED);
                if (this.notifications[type][index].status === 'new') {
                  this.notifications.unreadCount -= 1;
                  this.updateUnreadNotificationCount(this.notifications.unreadCount);
                };
                this.notifications[type][index].status = response.data.updatedData.status;
              }
            },
            error: (error) => {
              console.log('error saving follow up detail', error);
              this._toasterService.showError(error);
            },
          });
        break;
      case 'cancel':
        delete this.notifications[type][index].addFollowup;
        break;
      case 'clear':
        this.notifications[type][index].clearing = true;
        this._notificationService
          .updateNotification({
            id: this.notifications[type][index]._id,
            type: 'resolved',
          })
          .pipe(finalize(() => delete this.notifications[type][index].clearing))
          .subscribe({
            next: (response) => {
              if (response.statusCode == STATUS_CODES.OK) {
                this.notifications[type][index].status =
                  response.data.updatedData.status;
                this.notifications[type][index].updatedBy =
                  response.data.updatedBy;
                this.notifications[type][index].updatedAt = moment(
                  new Date(response.data.updatedAt)
                ).format();
                this._toasterService.show(
                  'Success',
                  MESSAGE_CONSTANTS.FOLLOWUP_CLEARED
                );
                this.notifications.unreadCount -= 1;
                this.updateUnreadNotificationCount(
                  this.notifications.unreadCount
                );
              }
            },
            error: (error) => {
              console.log('error updating notification', error);
              this._toasterService.showError(error);
            },
          });
        break;
    }
  }

  onInputChange(event: any, type: string, field: string, index: number) {
    this.tempNotificationStorage[type][index].followUpDetails[field] = event;
  }

  onMarkAllAsRead(event: any) {
    event.stopPropagation();
    this.isLoading.notifications = true;
    this._notificationService.markAllAsRead()
    .pipe(finalize(() => this.isLoading.notifications = false))
    .subscribe({
      next: (response) => {
        this.getNotifications(this.queryParams);
        this._toasterService.show('Success', MESSAGE_CONSTANTS.MARKED_ALL_READ);
      },
      error: (error) => console.log('error marking all notifications as read', error)
    })
  }
}
