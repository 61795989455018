import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { finalize } from 'rxjs';
import { NIGHTWEB_APP_CONSTANT, NODATA_MESSAGES, STATUS_CODES } from 'src/app/constants';
import { UserService } from 'src/app/services';
import { NightwebUtil } from 'src/app/utils';
import { InputType, TableConfig } from '../../common/interface';
import { ConfigurationModalComponent } from '../configuration-modal/configuration-modal.component';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { AddEditUserModalComponent } from '../add-edit-user-modal/add-edit-user-modal.component';

@Component({
  selector: 'app-link-user-modal',
  templateUrl: './link-user-modal.component.html',
  styleUrls: ['./link-user-modal.component.scss']
})
export class LinkUserModalComponent implements OnInit {

  linkUserForm!: FormGroup;

  loading = false;
  submitted = false;
  title!: string;
  formData: any = {};
  selectedUser!: any;
  selectedUserId: string = '';
  searchKeyword: string = '';
  organizationId: string = '';

  config = NIGHTWEB_APP_CONSTANT.modalConfig;
  configurationModalConfig!: MdbModalRef<ConfigurationModalComponent>;
  confirmationModalRef!: MdbModalRef<ConfirmationModalComponent>;
  userAddEditComponent!: MdbModalRef<AddEditUserModalComponent>;
  status_codes = STATUS_CODES;
  notFoundMessage = NODATA_MESSAGES.NO_USERS;
  tableConfig: TableConfig = {
    headerElements: [
      'User', 'Phone', 'Email'
    ],
    bodyElements: [
      { value: ['firstName', 'middleName', 'lastName'], dataType: 'displayName', objectKey: 'demographic', addonType: InputType.CHECKBOX },
      { value: 'phone', dataType: 'object', pipe: 'phone', objectKey: 'demographic' },
      { value: 'email', dataType: 'object', objectKey: 'demographic' }
    ],
    tableDatas: [],
    actionItems: []
  }
  tableHeadClass: string = 'bg-white text-gray-dark';

  constructor(
    private userServce: UserService,
    private formBuilder: FormBuilder,
    private nightWebUtil: NightwebUtil,
    private modalService: MdbModalService,
    public modalRef: MdbModalRef<LinkUserModalComponent>,
    private _userService: UserService
  ) { 
    this.setLinkUserForm();
  }

  ngOnInit(): void {
    this.getAllActiveUsers();
  }

  setLinkUserForm() {
    this.linkUserForm = this.formBuilder.group({
      keyword: [''],
      limit: [NIGHTWEB_APP_CONSTANT.defaultLimit],
      offset: []
    });
  }

  getAllActiveUsers(filter?: any) {
    this.loading = true;
    this.tableConfig.tableDatas = [];
    this.userServce.getAllEndUsers({ ...filter, activeDevice: null, ...this.organizationId !== 'System' && { organizationId: this.organizationId }  })
      .pipe(finalize(() => this.loading = false))
      .subscribe({
        next: (response) => {
          this.tableConfig.tableDatas = response.data.rows;
        },
        error: (e) => {
        }
      })
  }

  setupConfiguration(user?: any) {
    const { advocateId, safeWord, accountNumber } = this.selectedUser;
    if (advocateId && safeWord && accountNumber) {
      const config = this.nightWebUtil.shallowCopy(this.config);
      config.data.title = 'Configuration';
      config.modalClass = 'modal-dialog-scrollable modal-md';
      config.data.confirmButtonLabel = "Link";
      config.data.formData.module = "User";
      config.data.formData.mode = 'add';
      config.data.formData.module = 'user';
      config.data.formData.userId = this.selectedUserId;
      config.data.formData.organizationId = user.organizationId;
      config.data.formData.deviceId = this.formData?.selectedDeviceId;
      config.data.formData.configurations = user.configurations ?? [];
      // config.data.formData.deviceId = this.selectedDeviceId;
      this.modalRef.close();
      this.configurationModalConfig = this.modalService.open(ConfigurationModalComponent, config);
      // this.configurationModalConfig.onClose.subscribe((data: any) => {
      //   if (data) {
      //   }
      // });
    } 
    else {
      const config = this.nightWebUtil.shallowCopy(this.config);
      config.data.confirmButtonLabel = 'Update End Fields';
      config.data.formData = user;
      config.modalClass = 'modal-md';
      config.data.title = 'Update End User';
      const text = !advocateId && !safeWord && !accountNumber ? '<strong>Advocate</strong>, <strong>Safe Word</strong> and <strong>Account Number</strong>' 
        : !advocateId && !accountNumber ? '<strong>Advocate</strong> and <strong>Account Number</strong>' 
        : !advocateId && !safeWord ? '<strong>Advocate</strong> and <strong>Safe Word</strong>'
        : !safeWord && !accountNumber ? '<strong>Safe Word</strong> and <strong>Account Number</strong>'
        : !advocateId ? '<strong>Advocate</strong>'
        : !safeWord ? '<strong>Safe Word</strong>'
        : '<strong>Account Number</strong>'
      config.data.body = `The device cannot be linked with the user until the following required fields are updated: ${text} to <strong>${user?.demographic?.firstName + ' ' + user?.demographic?.middleName + ' ' + user?.demographic?.lastName}</strong>.  Please update these fields before proceeding.`;
      this.confirmationModalRef = this.modalService.open(ConfirmationModalComponent, config);
      this.confirmationModalRef.onClose.subscribe((data: any) => {
        if (data) {
          this.getEndUser(data.id);
        }
      });
    }
  }

  getEndUser(id: string) {
    this._userService.getUserById(id)
      .pipe()
      .subscribe({
        next: response => {
          this.openAddEditUserModal(response.data);
        },
        error: error => console.log('error', error)
      })
  }

  openAddEditUserModal(user: any) {
    const config = this.nightWebUtil.shallowCopy(this.config);
    config.data.title = 'Edit';
    config.modalClass = 'modal-dialog-scrollable modal-md';
    config.data.formData = user;
    this.userAddEditComponent = this.modalService.open(AddEditUserModalComponent, config);
    this.userAddEditComponent.onClose.subscribe((data: any) => {
      if (data) {
        const index = this.tableConfig.tableDatas.findIndex(user => user.id === data.id);
        this.tableConfig.tableDatas[index] = data.updatedData;
        this.selectedUser = this.tableConfig.tableDatas[index];
      };
    });
  }

  receiveSearchKeyword(keyword: any) {
    this.searchKeyword = keyword;
    this.linkUserForm.patchValue({ keyword });
    const filterParams = this.nightWebUtil.getFilterParams(this.linkUserForm.value)
    this.getAllActiveUsers(filterParams);
  }

  receiveEvent({ action, event: { target: { checked } }, object: { id } }: any) {
    if (action === 'change') {
      if (checked) {
        this.selectedUserId = id;
        this.selectedUser = this.tableConfig.tableDatas.find((user: any) => user.id == this.selectedUserId);
      } else {
        this.selectedUserId = '';
        this.selectedUser = null;
      }
    }
  }

}
