import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent implements OnInit {

  @Input() label = '';
  @Input() name = '';
  @Input() type = '';
  @Input() class = 'form-control app-input';
  @Input() submitted = false;
  @Input() control: any;
  @Input() mask: any;
  @Input() pattern: any;
  @Input() maxLength!: number;
  @Input() minLength!: number;
  @Input() required: boolean = false;
  @Input() placeholder: string = '';
  @Input() readonly: boolean = false;
  
  constructor() {

  }

  ngOnInit(): void {
  }

}
