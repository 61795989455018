import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { finalize } from 'rxjs';
import {
  MESSAGE_CONSTANTS,
  NIGHTWEB_APP_CONSTANT,
  STATUS_CODES,
} from '../../../constants';
import { AdminService, DeviceService, OrganizationService, ToasterService } from '../../../services';
import { NightwebUtil } from 'src/app/utils';

@Component({
  selector: 'app-add-edit-device-modal',
  templateUrl: './add-edit-device-modal.component.html',
  styleUrls: ['./add-edit-device-modal.component.scss'],
})
export class AddEditDeviceModalComponent implements OnInit {
  deviceForm!: FormGroup;
  loading = false;
  submitted = false;
  title!: string;
  formData: any = {};
  phoneMask = NIGHTWEB_APP_CONSTANT.phoneMask;
  status_codes = STATUS_CODES;
  organizationList: any[] = [];
  role: string = '';
  user: any;
  roles = NIGHTWEB_APP_CONSTANT.roles;

  constructor(
    private _formBuilder: FormBuilder,
    private _deviceService: DeviceService,
    private _toasterService: ToasterService,
    private _organizationService: OrganizationService,
    private _adminService: AdminService,
    private _nightwebUtil: NightwebUtil,
    public modalRef: MdbModalRef<AddEditDeviceModalComponent>
  ) {
    this.setDeviceForm();
    this.role = this._nightwebUtil.getRole();
  }

  ngOnInit(): void {
    this.formData && this.deviceForm.patchValue(this.formData);
    this.getAllOrganizations();
    this.getProfileInformation();
  }

  setDeviceForm() {
    this.deviceForm = this._formBuilder.group({
      id: [],
      brand: [NIGHTWEB_APP_CONSTANT.defaultBrandName, Validators.required],
      name: [null, Validators.required],
      modelNumber: [null],
      serialNumber: [null],
      imeiNumber: [null, Validators.required],
      associatedPhone: [null, Validators.required],
      operatingSystem: [NIGHTWEB_APP_CONSTANT.defaultOS],
      organizationId: [null, Validators.required]
    });
  }

  getProfileInformation() {
    this.loading = true;
    this._adminService.getSelf()
      .pipe(finalize(() => this.loading = false))
      .subscribe({
        next: (response) => {
          this.user = response.data;
        },
        error: (error) => console.log('error getting profile information', error)
      });
  }

  save() {
    if (this.deviceForm.valid) {
      this.loading = true;
      if (this.deviceForm.get('id')?.value) {
        this._deviceService
          .update(this.deviceForm.value)
          .pipe(
            finalize(() => {
              this.loading = false;
            })
          )
          .subscribe({
            next: (response) => {
              if (response.statusCode == this.status_codes.OK) {
                this.modalRef.close(response.data);
                this._toasterService.show(
                  'Success',
                  MESSAGE_CONSTANTS.DEVICE_UPDATED
                );
              }
            },
            error: (e) => {},
          });
      } else {
        this._deviceService
          .create(this.deviceForm.value)
          .pipe(
            finalize(() => {
              this.loading = false;
            })
          )
          .subscribe({
            next: (response) => {
              if (response.statusCode == this.status_codes.OK) {
                this.modalRef.close(response.data);
                this._toasterService.show(
                  'Success',
                  MESSAGE_CONSTANTS.DEVICE_ADDED
                );
              }
            },
            error: (e) => {},
          });
      }
    }
  }

  confirm() {
    this.modalRef.close();
  }

  close() {
    this.submitted = false;
    this.modalRef.close();
  }

  getAllOrganizations() {
    this._organizationService.getAllOrganizations()
      .pipe(finalize(() => this.loading = false))
      .subscribe({
        next: (response) => {
          this.organizationList = response.data.rows;
          if (this.organizationList.length === 1 && !this.formData) {
            this.deviceForm.patchValue({
              organizationId: this.organizationList[0]?.id
            });
          }
        },
        error: (error) => console.log('error fetching organization list', error)
      })
  }
}
