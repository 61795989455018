import { Injectable } from '@angular/core';
import { HttpClientService } from './http-client.service';
import { API_CONSTANTS } from '../constants';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MigrationService {

  constructor(
    private _http: HttpClientService
  ) { }

  getAllUsers(query?: any) {
    return this._http.get(API_CONSTANTS.MIGRATION, query)
    .pipe(
      map((response: any) => response)
    );
  }

  migrate(userIds: string[]) {
    return this._http.post(`${API_CONSTANTS.MIGRATION}/migrate`, { endUsersId: userIds })
      .pipe(
        map((response: any) => response)
      );
  }
}
