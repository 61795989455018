import { Component, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription, forkJoin } from 'rxjs';
import { AdminService, AuthService, DataService, NotificationService, RoleService } from '../../../services';
import { NightwebUtil } from 'src/app/utils';
import { MENU, NIGHTWEB_APP_CONSTANT } from '../../../constants';
import { NotificationsComponent } from '../../notifications/notifications.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  loggedInUser!: any;
  subscription!: Subscription;
  header:any[] = [];
  menus = MENU;
  unreadNotificationsCount: number = 0;
  datepickerSelected: boolean = false;
  timepickerSelected: boolean = false;
  isAdvocate: boolean = false;
  filterSelected!: any;

  @ViewChild(NotificationsComponent) notificationsComponent!: NotificationsComponent;

  constructor(
    private authService: AuthService,
    private dataService: DataService,
    private nightwebUtil: NightwebUtil,
    private router: Router,
    private _notificationService: NotificationService,
    private _roleService: RoleService,
    private _adminService: AdminService
  ) {
      this.loggedInUser = this.nightwebUtil.decodeToken(this.nightwebUtil.getLoggedInUser());
      this.dataService.getData().subscribe(
        (data) => {
          if (data?.key === 'update-profile') {
            this.loggedInUser = data?.user;
          }
        }
      );
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.header = [];
          this.menus.forEach((route: any)=>{
            if (event.url.includes(route.route)) {
              this.header.push(route);
              const url = event.url?.split('/')?.filter(x=>x);
              if(url?.[1] && route.children){
                this.header.push(route.children);
              }
            }
          });
        }
      });
      this.subscription = this._notificationService.notificationCount$.subscribe(
        (count) => {
          this.unreadNotificationsCount = count;
        }
      );
    }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngOnInit() {
    forkJoin([
      this._roleService.getAllRoles(),
      this._adminService.getSelf()
    ])
    .pipe()
    .subscribe({
      next: response => {
        const [roles, admin] = response;
        if (!admin.data.superRoleId) {
          const advocateRole = roles.data.rows.find((role: any) => role.code === NIGHTWEB_APP_CONSTANT.roles.ADVOCATE);
          this.isAdvocate = admin.data.organizations[0].roleId === advocateRole.id;
        };
      },
      error: error => console.log('error', error)
    });
  }

  logout() {
    this.authService.logout();
  }

  onPickerSelected(event: any) {
    const { type, isSelected = false } = event;
    switch(type) {
      case 'date':
        this.datepickerSelected = isSelected ? false : true;
        break;
      case 'time':
        this.timepickerSelected = isSelected ? false : true;
        break;
    }
  }

  onFilterSelected(event: any) {
    this.filterSelected = event;
    this.notificationsComponent.onFilterSelected(event);
  }
}
