import { Component, OnInit, ViewChild } from '@angular/core';
import { finalize } from 'rxjs';
import { NIGHTWEB_APP_CONSTANT } from '../../constants';
import { AdminService, DataService, RoleService } from '../../services';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit {

  loading: boolean = false;
  submitted: boolean = false;
  phoneMask = NIGHTWEB_APP_CONSTANT.phoneMask;
  skeletonTheme = NIGHTWEB_APP_CONSTANT.skeletonLoaderConfig;
  user!: any;
  isAdvocate: boolean = false;

  constructor(
    private dataService: DataService,
    private adminService: AdminService,
    private _roleService: RoleService
  ) { }

  ngOnInit(): void {
    this.getProfileInformation();

    this.dataService.getData().subscribe(
      (data) => {
        if (data) {
          this.getProfileInformation();
        }
      }
    )
  }

  getProfileInformation() {
    this.loading = true;
    this.adminService.getSelf()
      .pipe(finalize(() => this.loading = false))
      .subscribe({
        next: (response) => {
          this.user = response.data;
          !this.user.superRoleId && this.getAllRoles();
        },
        error: (e) => {
        }
      })
  }

  getAllRoles() {
    this._roleService.getAllRoles()
      .pipe()
      .subscribe({
        next: response => {
          const roles = response.data.rows;
          const advocateRole = roles.find((role: any) => role.code === NIGHTWEB_APP_CONSTANT.roles.ADVOCATE);
          this.isAdvocate = this.user.organizations[0].roleId === advocateRole.id;
        },
        error: error => console.log('error getting roles', error)
      });
  }
}
