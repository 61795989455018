import { Component, OnInit } from '@angular/core';
import { STATUS_CODES } from '../../../constants';
import { LayoutService } from '../../../services';

@Component({
  selector: 'app-layout-main',
  templateUrl: './layout-main.component.html',
  styleUrls: ['./layout-main.component.scss']
})
export class LayoutMainComponent implements OnInit {
  appVersion!: string;

  constructor(
    private _layoutService: LayoutService
  ) { }

  ngOnInit(): void {
    this.getAppVersion();
  }

  getAppVersion() {
    this._layoutService.getAppVersion()
      .pipe()
      .subscribe({
        next: response => {
          if (response.statusCode == STATUS_CODES.OK) {
            this.appVersion = `Version ${response.data.API_VERSION}`
          }
        },
        error: error => console.log('error getting application version', error)
      });
  }

}
