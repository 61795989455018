<form [formGroup]="activityLogForm">
    <div class="d-flex justify-content-between">
        <div class="d-flex">
            <app-search (emitSearchKeyword)="receiveSearchKeyword($event)"></app-search>
            <button type="button" class="btn btn-light bg-white border ms-2" (click)="advFilterCollapse.toggle()"
                [attr.aria-expanded]="!advFilterCollapse.collapsed" aria-controls="advanceFilterCollapse">
                <i-bs [name]="advFilterCollapse.collapsed ? 'filter': 'x-lg'"></i-bs> Filter
            </button>
        </div>
        <div class="d-flex justify-content-end ms-5 w-50">
            <app-select [form]="activityLogForm" [control]="activityLogForm.controls['logModule']"
                [controlName]="'logModule'" [clearButton]="true" [filter]="false" [submitted]="submitted" [data]="modules" [disabled]="!modules.length"
                [placeholder]="modules.length ? 'Select Module' : 'Loading...'" (emitSelectedValue)="receiveSelectedValue($event)" class="select-outline mx-2"></app-select>
            <app-select [form]="activityLogForm" [control]="activityLogForm.controls['logType']" [controlName]="'logType'"
                [clearButton]="true" [filter]="false" [submitted]="submitted" [data]="logTypes" [disabled]="!logTypes.length"
                [placeholder]="logTypes.length ? 'Select Log Type' : 'Loading...'" (emitSelectedValue)="receiveSelectedValue($event)" class="select-outline me-2"></app-select>
            <app-select [form]="activityLogForm" [control]="activityLogForm.controls['limit']" [controlName]="'limit'"
                [filter]="false" [submitted]="submitted" [data]="limits" [name]="'label'" [value]="'value'" [width]="defaultLimitSelectWidth"
                (emitSelectedValue)="receiveSelectedValue($event)" class="select-outline"></app-select>
        </div>
    </div>
    <div formGroupName="advanceFilters">
        <div class="row" id="advanceFilterCollapse" mdbCollapse #advFilterCollapse="mdbCollapse">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row g-3 mb-2">
                            <div class="col-3">
                                <label for="email" class="form-label">Organization</label>
                                <app-select [form]="advanceFilters" [control]="advanceFilters.controls['organizationId']"
                                    [controlName]="'organizationId'" [submitted]="submitted" [data]="organizationList"
                                    [name]="'name'" [value]="'value'" [clearButton]="true" (emitSelectedValue)="onOrganzationValueChanged($event)"
                                    [placeholder]="'Select organization'">
                                </app-select>
                            </div>
                            <div class="col-3">
                                <label for="advocate" class="form-label">Advocate</label><span class="spinner-grow spinner-grow-sm ms-1" role="status" aria-hidden="true" *ngIf="isLoading.advocateList"></span>
                                <app-select [form]="advanceFilters" [control]="advanceFilters.controls['advocateId']"
                                    [controlName]="'advocateId'" [submitted]="submitted" [data]="advocateList"
                                    [name]="'name'" [value]="'value'" [clearButton]="true" [disabled]="!advocateList.length"
                                    [placeholder]="'Select advocate'">
                                </app-select>
                            </div>
                            <div class="col-3">
                                <label for="createdFrom" class="form-label">Created From</label>
                                <mdb-form-control>
                                    <div class="datepicker-wrapper">
                                        <input mdbInput [readonly]="true" [mdbDatepicker]="createdFrom" type="text" class="form-control"
                                            id="createdFrom" formControlName="createdFrom" />
                                        <button *ngIf="advanceFilters.value.createdFrom" class="datepicker-clear-button" (click)="clearDatePicked('createdFrom')">
                                            <i class="fas fa-times"></i>
                                        </button>
                                        <mdb-datepicker-toggle [mdbDatepicker]="createdFrom"></mdb-datepicker-toggle>
                                        <mdb-datepicker [inline]="true" [format]="dateFormat" #createdFrom></mdb-datepicker>
                                    </div>
                                </mdb-form-control>
                            </div>
                            <div class="col-3">
                                <label for="createdTo" class="form-label">Created To</label>
                                <mdb-form-control>
                                    <div class="datepicker-wrapper">
                                        <input mdbInput [readonly]="true" [mdbDatepicker]="createdTo" type="text" class="form-control"
                                            id="createdTo" formControlName="createdTo" />
                                        <button *ngIf="advanceFilters.value.createdTo" class="datepicker-clear-button" (click)="clearDatePicked('createdTo')">
                                            <i class="fas fa-times"></i>
                                        </button>
                                        <mdb-datepicker-toggle [mdbDatepicker]="createdTo"></mdb-datepicker-toggle>
                                        <mdb-datepicker [inline]="true" [format]="dateFormat" #createdTo></mdb-datepicker>
                                    </div>
                                </mdb-form-control>
                            </div>
                        </div>
                        <div class="row g-3 d-flex justify-content-between">
                            <div class="col-3">
                                <label for="endUserId" class="form-label">End User</label>
                                <app-select [form]="advanceFilters" [control]="advanceFilters.controls['endUserId']"
                                    [controlName]="'endUserId'" [submitted]="submitted" [data]="endUserList"
                                    [name]="'name'" [value]="'value'" [clearButton]="true"
                                    [placeholder]="'Select end user'">
                                </app-select>
                            </div>
                            <div class="col-3 d-flex align-items-end">
                                <button type="button" class="btn btn-light border flex-grow-1 me-1"
                                    (click)="clear()">Clear</button>
                                <button type="button" class="btn btn-primary flex-grow-1  ms-1"
                                    (click)="search(true)">Search</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>

<div class="mt-3">
    <app-table [tableConfig]="tableConfig" [module]="'Check in module'" [loading]="loading" (onScrollToBottom)="scrollToBottom($event)"
        [notFoundMessage]="notFoundMessage" (sendEvent)="receiveEvent($event)">
    </app-table>
</div>