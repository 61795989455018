const NIGHTWEB_APP_CONSTANT = {
  userIdleTime: 1800,
  migrationTimeout: 120000,
  timeout: 30,
  showLimits: [
    { label: 'Show 20', value: 20 },
    { label: 'Show 50', value: 50 },
    { label: 'Show 100', value: 100 },
  ],
  defaultDate: '01/01/1900',
  defaultDatePipeFormat: 'MM/dd/yyyy',
  defaultDateFormat: 'MM/dd/YYYY',
  defaultMomentDateFormat: 'MM/DD/YYYY',
  defaultTimeFormat: 'hh:mm a',
  shorTimeFormat: 'hh:mm',
  defaultDateTimeFormat: 'MM/dd/yyyy - hh:mm a',
  shortDateTimeFormat: 'MM/dd/yyyy hh:mm a',
  defaultSubmitDateFormat: 'YYYY-MM-DD',
  mdbDefaultDateFormat: 'mm/dd/yyyy',
  dateMask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
  defaultPercentPipe: '',
  modalConfig: {
    animation: true,
    backdrop: true,
    data: {
      title: '',
      body: '',
      formData: {},
      confirmButtonLabel: '',
    },
    ignoreBackdropClick: true,
    keyboard: true,
    modalClass: 'modal-lg',
  },
  confirmPopConfig: {
    position: 'left',
    data: {
      title: '',
      body: '',
      confirmButtonLabel: '',
    },
  },
  skeletonLoaderConfig: {
    'border-radius': '0',
    height: '10px',
    margin: '0px',
  },
  inputLimit: {
    priceSeparatorLimit: '99999999999999',
    personNameMask: 'S* S*',
  },
  phonePattern: { '0': { pattern: new RegExp('^[0-9]*$') } },
  passwordPattern:
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@@$%^&*-]).{8,}$/,
  passwordMinLength: 8,
  phoneMask: '(000) 000-0000',
  emailPattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
  zipPattern: /^[0-9]{5}$/,
  defaultLimit: 20,
  defaultOffset: 1,
  debounceTime: 300,
  defaultBrandName: 'Samsung',
  defaultOS: 'Wear OS 5.0',
  defaultRoleId: '63be68153a670a046603a4fe',
  genderList: ['Male', 'Female', 'Other'],
  defaultLimitSelectWidth: '110px',
  roles: {
    SUPER_ADMIN: "SUPER_ADMIN",
    ADVOCATE: "ADVOCATE",
    CLIENT_ADMIN: "CLIENT_ADMIN"
  }
};

export { NIGHTWEB_APP_CONSTANT };
