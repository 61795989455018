import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { finalize, forkJoin } from 'rxjs';
import { MODULE, NIGHTWEB_APP_CONSTANT, NODATA_MESSAGES } from '../../constants';
import { ActivityLogService, AdminService, LookupService, OrganizationService, UserService } from '../../services';
import { DateUtility, NightwebUtil } from '../../utils';
import { TableConfig } from '../common/interface';

@Component({
  selector: 'app-activity-log',
  templateUrl: './activity-log.component.html',
  styleUrls: ['./activity-log.component.scss']
})
export class ActivityLogComponent implements OnInit {

  activityLogForm!: FormGroup;

  loading: boolean = false;
  submitted: boolean = false;
  limits = NIGHTWEB_APP_CONSTANT.showLimits;
  defaultDateTimeFormat = NIGHTWEB_APP_CONSTANT.defaultDateTimeFormat;
  defaultOffset = NIGHTWEB_APP_CONSTANT.defaultOffset;
  logs: any[] = [];
  modules: any[] = [];
  logTypes: any[] = [];
  searchKeyword: string = '';
  tableConfig: TableConfig = {
    headerElements: [
      'Created', 'Module', 'End User', 'Event Type', 'Log Type', 'Action'
    ],
    bodyElements: [
      { value: ['createdAt', 'name'], dataType: 'created', objectKey: 'createdBy' },
      { value: ['module', 'subModule'], dataType: 'module', objectKey: 'log' },
      { value: 'endUserName', dataType: 'string' },
      { value: ['type', 'subType'], dataType: 'eventType', objectKey: 'event' },
      { value: 'type', dataType: 'object', objectKey: 'log' },
      { value: 'note', dataType: 'object', objectKey: 'log', showTooltip: true },
    ],
    tableDatas: [],
    actionItems: [
    ]
  }
  section = MODULE.ACTIVITY_LOGS;
  notFoundMessage = NODATA_MESSAGES.NO_ACTIVITY_LOGS;
  isLoading = {
    advocateList: false
  };
  totalDataCount: number = 0;
  defaultLimitSelectWidth : string = NIGHTWEB_APP_CONSTANT.defaultLimitSelectWidth;
  dateFormat = NIGHTWEB_APP_CONSTANT.mdbDefaultDateFormat;
  endUserList = [];
  organizationList = [];
  advocateList = [];
  filterValues: any = {};

  constructor(
    private formBuilder: FormBuilder,
    private nightWebUtil: NightwebUtil,
    private lookupService: LookupService,
    private activityLogService: ActivityLogService,
    private _userService: UserService,
    private _organizationService: OrganizationService,
    private _adminService: AdminService,
    private _dateUtility: DateUtility
  ) {
    this.setActivityLogForm();
    this.filterValues = this.activityLogForm.value;
  }

  ngOnInit(): void {
    this.loading = true;
    forkJoin([
      this.activityLogService.getActivityLogs({limit: NIGHTWEB_APP_CONSTANT.defaultLimit, offset: this.defaultOffset}),
      this.lookupService.getSystemModules(),
      this.lookupService.getLogTypes(),
      this._userService.getAllEndUsers(),
      this._organizationService.getAllOrganizations(),
      this._adminService.getAdvocates()
    ])
      .pipe(finalize(() => this.loading = false))
      .subscribe({
        next: (response) => {
          const [logs, modules, logTypes, endUsers, organizations, advocates] = response;
          this.endUserList = endUsers.data.rows.map((user: any) => ({ name: `${user.demographic.firstName} ${user.demographic.middleName} ${user.demographic.lastName}`, value: user.id }));
          this.organizationList = organizations.data.rows.map((organization: any) => ({ name: organization.displayName || organization.businessName, value: organization.id }));
          this.advocateList = advocates.data.rows.map((advocate: any) => ({ name: `${advocate.demographic.firstName} ${advocate.demographic.middleName} ${advocate.demographic.lastName}`, value: advocate.id }));
          this.logs = logs.data.rows;
          this.tableConfig.tableDatas = this.logs.map(log => {
            return {
              ...log,
              createdBy: {
                ...log.createdBy,
                createdAt: log.createdAt
              }
            }
          });
          this.modules = modules.data;
          this.logTypes = logTypes.data;
        },
        error: (error) => { console.log('error', error) }
      })
  }

  setActivityLogForm() {
    this.activityLogForm = this.formBuilder.group({
      limit: [NIGHTWEB_APP_CONSTANT.defaultLimit],
      offset: [this.defaultOffset],
      keyword: [null],
      logModule: [null],
      logType: [null],
      advanceFilters: this.formBuilder.group({
        createdFrom: [null],
        createdTo: [null],
        organizationId: [null],
        advocateId: [null],
        endUserId: [null],
      }),
    });
  }

  get advanceFilters(): FormGroup {
    return this.activityLogForm.get('advanceFilters') as FormGroup;
  }

  getActivityLogs() {
    delete this.filterValues.advanceFilters;
    this.filterValues.offset = this.activityLogForm.value.offset;
    const filter = this.nightWebUtil.getFilterParams(this.filterValues);
    this.loading = this.filterValues.offset === this.defaultOffset;
    this.filterValues.offset === this.defaultOffset && (this.tableConfig.tableDatas = []);
    this.activityLogService.getActivityLogs(filter)
      .pipe(finalize(() => {
        this.loading = false;
      }))
      .subscribe({
        next: (response) => {
          this.totalDataCount = response.data.count;
          this.logs = this.filterValues.offset !== this.defaultOffset ? ([...this.logs, ...response.data.rows]) : response.data.rows ;
          this.tableConfig.tableDatas = this.logs.map(log => {
            return {
              ...log,
              createdBy: {
                ...log.createdBy,
                createdAt: log.createdAt
              }
            }
          });
        },
        error: (e) => {
        }
      });
  }

  receiveSelectedValue(event: any) {
    this.activityLogForm.patchValue({
      [event.controlName]: event.value
    });
    this.filterValues[event.controlName] = event.value;
    this.activityLogForm.controls['offset'].setValue(this.defaultOffset);
    this.getActivityLogs();
  }

  receiveEvent({ action, event, object }: any) {
  }

  receiveSearchKeyword(keyword: any) {
    this.searchKeyword = keyword;
    this.activityLogForm.patchValue({ keyword, offset: this.defaultOffset });
    this.filterValues.keyword = keyword;
    this.getActivityLogs();
  }

  scrollToBottom(event: any) {
    if(this.logs.length !== this.totalDataCount){
      if(event.scrolledToBottom){
        this.activityLogForm.controls['offset'].setValue(this.activityLogForm.value.offset + 1);
        this.getActivityLogs();
      }
    }
  }

  clear() {
    this.activityLogForm.controls['advanceFilters'].reset();
  }

  search(isSearched: boolean = false) {
    isSearched && this.activityLogForm.controls['offset'].setValue(this.defaultOffset);
    this.filterValues = {
      ...this.activityLogForm.value,
      ...this.activityLogForm.controls['advanceFilters'].value,
      createdFrom: this._dateUtility.formatDate(this.activityLogForm.controls['advanceFilters'].value.createdFrom),
      createdTo: this._dateUtility.formatDate(this.activityLogForm.controls['advanceFilters'].value.createdTo)
    };
    this.getActivityLogs();
  }

  clearDatePicked(type: string) {
    this.activityLogForm.get('advanceFilters')?.get(type)?.setValue(null);
  }

  onOrganzationValueChanged(event: any) {
    this.isLoading.advocateList = true;
    this.advanceFilters.get('advocateId')?.setValue(null);
    this._adminService.getAdvocates({ ...event.value && { organizationId: event.value } })
      .pipe(finalize(() => this.isLoading.advocateList = false))
      .subscribe({
        next: response => {
          this.advocateList = response.data.rows.map((advocate: any) => ({ name: `${advocate.demographic.firstName} ${advocate.demographic.middleName} ${advocate.demographic.lastName}`, value: advocate.id }));
        },
        error: error => {
          console.log('error fetching advocates', error)
        }
      })
  }
}
