import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { MdbPopconfirmRef, MdbPopconfirmService } from 'mdb-angular-ui-kit/popconfirm';
import { finalize, forkJoin } from 'rxjs';
import { MESSAGE_CONSTANTS, NIGHTWEB_APP_CONSTANT, NODATA_MESSAGES, STATUS_CODES, USER_STATUS } from '../../constants';
import { AdminService, NotificationService, OrganizationService, RoleService, ToasterService } from '../../services';
import { NightwebUtil } from 'src/app/utils';
import { PopConfirmComponent, TableComponent } from '../common';
import { TableConfig } from '../common/interface';
import { AddEditAdministratorModalComponent, ConfirmationModalComponent } from '../modals';
import { Role } from '../../enums';

@Component({
  selector: 'app-administrator',
  templateUrl: './administrator.component.html',
  styleUrls: ['./administrator.component.scss']
})
export class AdministratorComponent implements OnInit {

  administratorForm!: FormGroup;

  loading: boolean = false;
  submitted: boolean = false;
  administrators: any[] = [];
  roles: any[] = [];
  organizations: any[] = [];
  searchKeyword: string = '';

  config = NIGHTWEB_APP_CONSTANT.modalConfig;
  confirmPopConfig = NIGHTWEB_APP_CONSTANT.confirmPopConfig;
  administratorAddEditModalComponent!: MdbModalRef<AddEditAdministratorModalComponent>;
  popconfirmRef: MdbPopconfirmRef<PopConfirmComponent> | null = null;
  confirmationModalRef!: MdbModalRef<ConfirmationModalComponent>;
  @ViewChild(TableComponent) tableComponent!: TableComponent;

  limits = NIGHTWEB_APP_CONSTANT.showLimits;
  defaultDateFormat = NIGHTWEB_APP_CONSTANT.defaultDateFormat;
  isWriteAccessible: boolean = this.nightWebUtil.isAccessible('Admins', 'W');
  filter: any;
  tableConfig: TableConfig = {
    headerElements: [
      'Name', 'Role', 'Contact', 'Status', 'Updated', ''
    ],
    bodyElements: [
      { value: ['displayName', 'displayOrganization'], dataType: 'adminDemographic', subModuleType: 'CHIP' },
      { value: 'roleName', dataType: 'array', objectKey: 'organizations' },
      { value: ['email', 'phone'], dataType: 'contact', objectKey: 'demographic' },
      { value: 'activationStatus', dataType: 'status', objectKey: 'activationStatus', navigation: true },
      { value: ['updatedAt', 'name'], dataType: 'updated', objectKey: 'updatedBy', navigation: true },
      { value: '', actionButton: true },
    ],
    tableDatas: [],
    actionItems: [
      {
        name: null,
        eventChecker: 'Activate/Deactivate User',
        eventName: 'userActivationModule',
      },
    ]
  };
  notFoundMessage = NODATA_MESSAGES.NO_ADMINISTRATORS;
  totalDataCount: number = 0;
  defaultLimitSelectWidth: string = NIGHTWEB_APP_CONSTANT.defaultLimitSelectWidth;

  constructor(
    private formBuilder: FormBuilder,
    private roleService: RoleService,
    private nightWebUtil: NightwebUtil,
    private adminService: AdminService,
    private modalService: MdbModalService,
    private toasterService: ToasterService,
    private popconfirmService: MdbPopconfirmService,
    private organizationService: OrganizationService,
    private _notificationService: NotificationService
  ) {
    this.setAdministratorForm();
    if (this.isWriteAccessible) {
      this.tableConfig.actionItems?.unshift(
        { name: 'Edit', eventName: 'editModule' },
        { name: 'Delete', icon: 'trash', eventName: 'deleteModule' }
      );
    }
  }

  ngOnInit(): void {
    // this.getAllAdministrators();
    this.loading = true;
    forkJoin([
      this.adminService.getAllAdministrators({limit: NIGHTWEB_APP_CONSTANT.defaultLimit, offset: NIGHTWEB_APP_CONSTANT.defaultOffset}),
      this.roleService.getAllRoles(),
      this.organizationService.getAllOrganizations()
    ])
      .pipe(finalize(() => this.loading = false))
      .subscribe({
        next: (response) => {
          const [admins, roles, organizations] = response;
          this.administrators = admins.data.rows;
          this.roles = roles.data.rows.map((role: any) => ({ ...role, label: role.name, value: role.id }));
          this.organizations = organizations.data.rows.map((organization: any) => ({ ...organization, label: organization.displayName || organization.businessName, value: organization.id }));
          this.tableConfig.tableDatas = this.administrators;
          this.mapRoles();
        },
        error: (e) => { }
      });
      this._notificationService
        .listen('admin-update')
        .subscribe({
          next: response => {
            if (response?.data) {
              const index = this.tableConfig.tableDatas.findIndex(admininstrator => admininstrator.id === response.data.id);
              this.tableConfig.tableDatas[index].activationStatus = USER_STATUS.ACTIVATED;
              this.toasterService.show('Success', MESSAGE_CONSTANTS.ADMIN_STATUS_UPDATED);
            };
          },
          error: error => console.log('error getting updated admin status', error)
        });
  }

  setAdministratorForm() {
    this.administratorForm = this.formBuilder.group({
      keyword: [''],
      limit: [NIGHTWEB_APP_CONSTANT.defaultLimit],
      offset: [NIGHTWEB_APP_CONSTANT.defaultOffset],
      organizationId: [null],
      roleId: [null]
    });
  }

  mapRoles() {
    this.administrators = this.administrators.map((admin: any) => {
      if (admin?.organizations?.length) {
        const organization = this.organizations.find(org => org?.id === admin?.organizations[0]?.organizationId);
        admin.displayOrganization = organization?.displayName ?? '';
      }
      return admin;
    });
    this.tableConfig.tableDatas = this.tableConfig.tableDatas.map((admin: any) => {
      if (!admin.superRoleId) {
        admin.organizations = admin.organizations?.map((org: any) => {
          const role = this.roles.find(x => x.id === org.roleId);
          if (role) {
            return {
              ...org,
              roleName: role.name
            }
          } else {
            return { ...org };
          }
        });
        return {
          ...admin,
          updatedBy: {
            ...admin.updatedBy,
            updatedAt: admin.updatedAt
          }
        };
      } else {
        return {
          ...admin,
          updatedBy: {
            ...admin.updatedBy,
            updatedAt: admin.updatedAt
          },
          roleName: this.roles.find(x => x.id === admin.superRoleId)?.name
        };
      }
    });
  }

  getAllAdministrators() {
    this.filter = this.nightWebUtil.getFilterParams(this.administratorForm.value);
    this.loading = this.filter.offset === NIGHTWEB_APP_CONSTANT.defaultOffset;
    this.filter.offset === NIGHTWEB_APP_CONSTANT.defaultOffset && (this.tableConfig.tableDatas = []);
    this.adminService.getAllAdministrators(this.filter)
      .pipe(finalize(() => {
        this.loading = false;
      }))
      .subscribe({
        next: (response) => {
          this.totalDataCount = response.data.count;
          response.data.rows = response.data.rows.map((res: any) => ({ ...res, label: `${res.demographic.firstName} ${res.demographic.lastName}`, value: res.id }))
          this.administrators = this.filter.offset !== NIGHTWEB_APP_CONSTANT.defaultOffset ? ([...this.administrators, ...response.data.rows]) : response.data.rows ;
          this.tableConfig.tableDatas = this.administrators;
          this.mapRoles();
        },
        error: (e) => {
        }
      })
  }

  openAddAdministratorModal(user?: any) {
    const config = this.nightWebUtil.shallowCopy(this.config);
    config.data.title = user ? 'Edit' : 'Add';
    config.modalClass = 'modal-dialog-scrollable modal-md';
    config.data.formData = user;
    this.administratorAddEditModalComponent = this.modalService.open(AddEditAdministratorModalComponent, config);
    this.administratorAddEditModalComponent.onClose.subscribe((data: any) => {
      if (data) {
        if (data.updatedData) {
          const roleName = data.updatedData.organizations ? this.roles.find(role => role.id === data.updatedData.organizations[0].roleId).name : Role.SUPER_ADMIN;
          data.updatedData.organizations ? (data.updatedData.organizations[0].roleName = roleName) : (data.updatedData.roleName = roleName);
          const index = this.administrators.findIndex(administrator => administrator.id === data.updatedData.id);
          this.tableComponent.updateTable('update', data, index);  
        } else {
          const roleName = this.roles.find(role => role.id === data.organizations[0].roleId).name;
          data.organizations[0].roleName = roleName;
          const { firstName, middleName, lastName } = data.demographic;
          data = { ...data, displayName: `${firstName} ${middleName} ${lastName}` };
          this.administrators.unshift(data);
          this.tableComponent.updateTable('add', data, 0)
        };
      };
    });
  }

  deleteAdmin(event: Event, user: any) {
    const target = event.target as HTMLElement;
    const config = this.nightWebUtil.shallowCopy(this.confirmPopConfig);
    config.data.confirmButtonLabel = 'Delete';
    config.data.title = `Delete`;
    config.data.body = MESSAGE_CONSTANTS.DELETE_CONFIRMATION;
    this.popconfirmRef = this.popconfirmService.open(PopConfirmComponent, target, config);
    this.popconfirmRef.onConfirm.subscribe(() => {
      this.adminService.delete(user.id)
        .subscribe({
          next: (response) => {
            this.administrators.splice(this.administrators.findIndex(x => x.id == user.id), 1);
            this.tableConfig.tableDatas = this.administrators;
            this.mapRoles();
            this.toasterService.show("Success", MESSAGE_CONSTANTS.ADMIN_REMOVED);
          },
          error: (e) => {
          }
        })
    });
  }

  receiveEvent({ action, event, object }: any) {
    if(action === 'edit') {
      this.openAddAdministratorModal(object);
    } else if(action === 'delete') {
      this.deleteAdmin(event, object);
    } else if(action === 'userActivationModule') {
      this.openUserActivationModal(object);
    }
  }

  openUserActivationModal(user?: any) {
    const config = this.nightWebUtil.shallowCopy(this.config);
    config.data.confirmButtonLabel = user.activationStatus.toLowerCase() === USER_STATUS.ACTIVATED ? 'Deactivate' : 'Activate';
    config.data.formData = user;
    config.modalClass = 'modal-dialog-scrollable modal-md';
    config.data.title = user.activationStatus.toLowerCase() === USER_STATUS.ACTIVATED ? 'Deactivate User' : 'Activate User';
    config.data.body = `Are you sure you want to ${user.activationStatus.toLowerCase() === USER_STATUS.ACTIVATED ? 'Deactivate' : 'Activate'} <strong>${user?.demographic?.firstName + ' ' + user?.demographic?.middleName + ' ' + user?.demographic?.lastName}</strong>?`;
    this.confirmationModalRef = this.modalService.open(ConfirmationModalComponent, config);

    this.confirmationModalRef.onClose.subscribe((data: any) => {
      const body = {
        activationStatus: data.activationStatus.toLowerCase() === USER_STATUS.ACTIVATED ? USER_STATUS.DEACTIVATED : USER_STATUS.ACTIVATED
      };
      this.adminService.changeStatus(data.id, body).subscribe({
        next: response => {
          if(response && response.statusCode == STATUS_CODES.OK) {
            const index = this.tableConfig.tableDatas.findIndex((data: any) => data.id === user.id);
            this.tableConfig.tableDatas[index] = {...this.tableConfig.tableDatas[index], activationStatus: response.data.activationStatus};
            this.toasterService.show('Success', MESSAGE_CONSTANTS.ADMIN_STATUS_UPDATED);
          }
        },
        error: error => {
          console.log('error changing status', error);
          this.toasterService.showError(error);
        }
      });
    });
  }

  receiveSelectedValue(event: any) {
    const filters = ['limit', 'organizationId', 'roleId'];
    if(event) {
      this.administratorForm.patchValue({
        [event.controlName]: event.value
      });
      if(filters.includes(event.controlName)) {
        this.administratorForm.controls['offset'].setValue(NIGHTWEB_APP_CONSTANT.defaultOffset);
        this.getAllAdministrators();
      }
    }
  }

  receiveSearchKeyword(keyword: any) {
    this.searchKeyword = keyword;
    this.administratorForm.patchValue({ keyword, offset: NIGHTWEB_APP_CONSTANT.defaultOffset });
    this.getAllAdministrators();
  }

  scrollToBottom(event: any) {
    if(this.administrators.length !== this.totalDataCount){
      if(event.scrolledToBottom){
        this.administratorForm.controls['offset'].setValue(this.administratorForm.value.offset + 1);
        this.getAllAdministrators();
      }
    }
  }  

}
