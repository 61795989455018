import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MdbSidenavComponent } from 'mdb-angular-ui-kit/sidenav';
import { MESSAGE_CONSTANTS, NIGHTWEB_APP_CONSTANT, TEXT_EDITOR } from '../../../constants';
import { NightwebUtil } from '../../../utils';
import { ToasterService, UserService } from 'src/app/services';
import { finalize } from 'rxjs';
import { STATUS_CODES } from '../../../constants'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MdbPopconfirmRef, MdbPopconfirmService } from 'mdb-angular-ui-kit/popconfirm';
import { PopConfirmComponent } from '../../common';
import * as moment from 'moment';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.css']
})
export class NotesComponent implements OnInit {

  editorConfig = TEXT_EDITOR;
  defaultDateTimeFormat = NIGHTWEB_APP_CONSTANT.defaultDateTimeFormat;
  isLoading = {
    addingNote: false,
    updatingNote: false,
    deletingNote: false
  };
  noteForm!: FormGroup;
  selectedNoteIndex!: number | null;
  popconfirmRef: MdbPopconfirmRef<PopConfirmComponent> | null = null;
  confirmPopConfig = NIGHTWEB_APP_CONSTANT.confirmPopConfig;

  @Input() isFetchingNotes: boolean = false;
  @Input() data: any[] = [];
  @Input() notedUserId!: string;
  
  @ViewChild('sidenav', { static: false }) public sidenav!: MdbSidenavComponent;

  constructor(
    private _nightwebUtil: NightwebUtil,
    private _userService: UserService,
    private _toasterService: ToasterService,
    private _formBuilder: FormBuilder,
    private _popconfirmService: MdbPopconfirmService,

  ) { 
    this.setNoteForm();
  }

  ngOnInit(): void {
  }

  setNoteForm() {
    this.noteForm = this._formBuilder.group({
      body: [null, Validators.required],
      id: [null]
    })
  }

  getInitials() {
    if(this.data.length){
      this.data.forEach(datum => {
        datum.updatedBy.initials = this._nightwebUtil.getInitials(datum.updatedBy);
      });
    }
  }

  onOpenNotes() {
    this.getInitials();
    this.sidenav.toggle();
  }
  
  onSubmit(event: string) {
    this.editorConfig.editable = false;
    if(event === 'update') {
      this.isLoading.updatingNote = true;
      this._userService.updateNote({ currentText: this.noteForm.value.body }, this.noteForm.value.id)
        .pipe(finalize(() => { 
          this.isLoading.updatingNote = false; 
          this.editorConfig.editable = true;
        }))
        .subscribe({
          next: response => {
            if(response.statusCode == STATUS_CODES.OK){
              const note = (this.selectedNoteIndex || this.selectedNoteIndex === 0) && this.data[this.selectedNoteIndex];
              note.currentText = response.data.updatedData.currentText;
              note.updatedAt = moment().format();
              if(this.selectedNoteIndex){
                this.data[this.selectedNoteIndex] = note;
              };
              this._toasterService.show('Success', MESSAGE_CONSTANTS.NOTE_UPDATED);
              this.selectedNoteIndex = null;
              this.noteForm.controls['body'].setValue(null);
            }
          }, error: error => {
              console.log('error', error);
              this._toasterService.showError(error);
          }
        })
    } else {
        this.isLoading.addingNote = true;
        const note = {
          currentText: this.noteForm.value.body,
          endUserId: this.notedUserId
        }
        this._userService.addNote(note)
          .pipe(finalize(() => { 
            this.isLoading.addingNote = false;
            this.editorConfig.editable = true;
          }))
          .subscribe({
            next: response => {
              if (response.statusCode == STATUS_CODES.OK) {
                response.data.updatedBy.initials = this._nightwebUtil.getInitials(response.data.updatedBy);
                this.data.unshift(response.data);
                this._toasterService.show('Success', MESSAGE_CONSTANTS.NOTE_ADDED);
                this.noteForm.reset();
              }
            }, error: error => {
                console.log('error', error);
                this._toasterService.showError(error);
              }
            })
    }
  }

  onCancel(event: string) {
    event === 'update' && (this.selectedNoteIndex = null);
    this.noteForm.reset();
  }

  onEdit(data: any, index: number) {
    this.selectedNoteIndex = index;
    this.noteForm.controls['body'].setValue(data.currentText);
    this.noteForm.controls['id'].setValue(data.id);
  }

  onDelete(id: string, event: Event, index: number) {
    this.selectedNoteIndex = index;
    const target = event.target as HTMLElement;
    const config = this._nightwebUtil.shallowCopy(this.confirmPopConfig);
    config.data.confirmButtonLabel = 'Delete';
    config.data.title = `Delete`;
    config.data.body = MESSAGE_CONSTANTS.DELETE_CONFIRMATION;
    this.popconfirmRef = this._popconfirmService.open(PopConfirmComponent, target, config);
    this.popconfirmRef.onConfirm.subscribe(() => {
      this.isLoading.deletingNote = true;
      this._userService.deleteNote(id)
        .pipe(finalize(() => { 
          this.isLoading.deletingNote = false;
          this.selectedNoteIndex = null;
        }))
        .subscribe({
          next: response => {
            if(response.statusCode == STATUS_CODES.OK) {
              const indexToDelete = this.data.findIndex(datum => datum.id === id);
              this.data.splice(indexToDelete, 1);
              this._toasterService.show('Success', MESSAGE_CONSTANTS.NOTE_DELETED);
            }
          },
          error: error => {
            console.log('error', error);
            this._toasterService.showError(error);
          }
        })
    });
  }

  onClose() {
    this.sidenav.toggle();
  }
}
