import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MESSAGE_CONSTANTS, NIGHTWEB_APP_CONSTANT, NODATA_MESSAGES, STATUS_CODES } from '../../constants';
import { TableConfig } from '../common/interface';
import { AdminService, MigrationService, NotificationService, OrganizationService, ToasterService } from '../../services';
import { finalize, forkJoin } from 'rxjs';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { ConfirmationModalComponent } from '../modals';
import { NightwebUtil } from '../../utils';
import { TableComponent } from '../common';

@Component({
  selector: 'app-migrate-end-users',
  templateUrl: './migrate-end-users.component.html',
  styleUrls: ['./migrate-end-users.component.css']
})
export class MigrateEndUsersComponent implements OnInit {
  migrateEndUsersForm!: FormGroup;
  organizations: any[] = [];
  advocates: any[] = [];
  statuses: any[] = [
    { label: 'Migrated End Users', value: 'migrated' }, 
    { label: 'End Users to Migrate', value: 'notMigrated' }
  ];
  isLoading = {
    tableData: false,
    migration: false
  };
  defaultLimitSelectWidth: string = NIGHTWEB_APP_CONSTANT.defaultLimitSelectWidth;
  tableConfig: TableConfig = {
    headerElements: [
      '', 'Name', 'Contact', 'Address', 'Linked Device', 'Advocate'
    ],
    bodyElements: [
      { value: '', dataType: 'checkbox' },
      { value: ['displayName', 'status'], dataType: 'adminDemographic',  objectKey: 'demographic', subModuleType: 'CHIP' },
      { value: ['email', 'phone'], dataType: 'contact', objectKey: 'demographic' },
      { value: 'address', dataType: 'address' },
      { value: ['associatedPhone', 'imeiNumber'], dataType: 'device', objectKey: 'activeDevice' },
      { value: ['displayName', 'displayOrganization'], dataType: 'adminDemographic', subModuleType: 'CHIP' },
    ],
    tableDatas: [],
  };
  notFoundMessage = NODATA_MESSAGES.NO_USERS;
  confirmationModalRef!: MdbModalRef<ConfirmationModalComponent>;
  config = NIGHTWEB_APP_CONSTANT.modalConfig;
  selectedUsers: any[] = [];
  totalDataCount: number = 0;

  @ViewChild(TableComponent) tableComponent!: TableComponent;

  constructor(
    private _formBuilder: FormBuilder,
    private _adminService: AdminService,
    private _toasterService: ToasterService,
    private _organizationService: OrganizationService,
    private _modalService: MdbModalService,
    private _nightWebUtil: NightwebUtil,
    private _migrationService: MigrationService,
    private _notificationService: NotificationService
  ) { 
    this.setMigrateEndUserForm();
  }

  ngOnInit(): void {
    this.getAllUsers();
    forkJoin([
      this._adminService.getAdvocates(),
      this._organizationService.getAllOrganizations()
    ]).pipe(finalize(() => { }))
      .subscribe({
        next: response => {
          const [advocates, organizations] = response;
          
          if(advocates.statusCode == STATUS_CODES.OK){
            this.advocates = advocates.data.rows.map((advocate: any) => {
              const { firstName, lastName } = advocate.demographic;
              return {
                label: `${firstName} ${lastName}`,
                value: advocate.id
              };
            });
          };

          if(organizations.statusCode == STATUS_CODES.OK){
            this.organizations = organizations.data.rows.map((organization: any) => {
              return {
                label: organization.displayName || organization.businessName, 
                value: organization.id 
              };
            });
          };
        },
        error: error => console.log('error', error)
      });
      this._notificationService
      .listen('device_migration_success')
      .subscribe({
        next: response => {
          if (response?.data) {
            const index = this.tableConfig.tableDatas.findIndex(user => user.id === response.data.userId);
            this.tableComponent.updateTable('remove', response.data, index);
            this.selectedUsers.splice(this.selectedUsers.findIndex(user => user.id === response.data.userId), 1);
            
            if (!this.selectedUsers.length) {
              this._toasterService.show("Success", MESSAGE_CONSTANTS.MIGRATION_SUCCESS);
              this.isLoading.migration = false;
              this.selectedUsers.length = 0;
            }
          };
        },
        error: error => console.log('error getting updated state status', error)
      });
  }

  setMigrateEndUserForm() {
    this.migrateEndUsersForm = this._formBuilder.group({
      keyword: [null],
      organizationId: [null],
      advocateId: [null],
      status: ['notMigrated'],
      offset: [NIGHTWEB_APP_CONSTANT.defaultOffset],
      limit: [NIGHTWEB_APP_CONSTANT.defaultLimit]
    });
  }

  getAdvocates(){
    this._adminService.getAdvocates()
      .pipe(finalize(() => { }))
        .subscribe({
          next: (response:any) => {
            if(response.statusCode == STATUS_CODES.OK){
              this.advocates = response.data.rows.map((advocate: any) => {
                const { firstName, lastName } = advocate.demographic;
                return {
                  label: `${firstName} ${lastName}`,
                  value: advocate.id
                };
              });
            };
          }, error: (error) => {
            console.log('error getting advocates', error);
            this._toasterService.showError(error);
          }
        });
  }

  receiveSearchKeyword(keyword: any) {
    this.tableConfig.tableDatas.length = 0;
    this.migrateEndUsersForm.patchValue({ keyword, offset: NIGHTWEB_APP_CONSTANT.defaultOffset });
    this.getAllUsers();
  }

  getAllUsers() {
    this.isLoading.tableData = this.migrateEndUsersForm.get('offset')?.value === NIGHTWEB_APP_CONSTANT.defaultOffset;
    const { organizationId, advocateId, keyword, status, offset, limit } = this.migrateEndUsersForm.value;
    const params = {
      limit,
      offset,
      ...(keyword && { keyword }),
      ...(organizationId && { organizationId }),
      ...(advocateId && { advocateId }),
      ...(status !== 'notMigrated' && { migrationStatus: status })
    };
    this._migrationService.getAllUsers(params)
      .pipe(finalize(() => { 
        this.isLoading.tableData = false;
      }))
      .subscribe({
        next: response => {
          this.totalDataCount = response.data.count;
          const data = response.data.rows.map((row: any) => ({ 
            ...row,
            demographic: { ...row.demographic, displayName: `${row.demographic.firstName} ${row.demographic.middleName} ${row.demographic.lastName}`, status: row.migrationStatus === 'failed' && MESSAGE_CONSTANTS.MIGRATION_FAILED },
            address: { ...row.demographic.address.home }, 
            displayName: row.advocate?.name ?? '',
            displayOrganization: row.advocate?.organizationName ?? '',
            isProcessing: row.migrationStatus === 'migrating',
          }));
          this.tableConfig.tableDatas = this.migrateEndUsersForm.get('offset')?.value !== NIGHTWEB_APP_CONSTANT.defaultOffset ? ([...this.tableConfig.tableDatas, ...data]) : data ;
        },
        error: error => {
          this._toasterService.showError(error);
          console.log('error getting users', error);
        }
      });
  }

  receiveSelectedValue(event: any) {
    if (event) {
      this.migrateEndUsersForm.patchValue({
        [event.controlName]: event.value
      });

      if (this.migrateEndUsersForm.get('status')?.value === 'notMigrated') {
        this.tableConfig = {
          headerElements: [
            '', 'Name', 'Contact', 'Address', 'Linked Device', 'Advocate'
          ],
          bodyElements: [
            { value: '', dataType: 'checkbox' },
            { value: ['firstName', 'middleName', 'lastName'], dataType: 'displayName', objectKey: 'demographic' },
            { value: ['email', 'phone'], dataType: 'contact', objectKey: 'demographic' },
            { value: 'address', dataType: 'address' },
            { value: ['associatedPhone', 'imeiNumber'], dataType: 'device', objectKey: 'activeDevice' },
            { value: ['displayName', 'displayOrganization'], dataType: 'adminDemographic', subModuleType: 'CHIP' },
          ],
          tableDatas: [],
        };
      } else {
        this.tableConfig = {
          headerElements: [
            'Name', 'Contact', 'Address', 'Linked Device', 'Advocate'
          ],
          bodyElements: [
            { value: ['firstName', 'middleName', 'lastName'], dataType: 'displayName', objectKey: 'demographic' },
            { value: ['email', 'phone'], dataType: 'contact', objectKey: 'demographic' },
            { value: 'address', dataType: 'address' },
            { value: ['associatedPhone', 'imeiNumber'], dataType: 'device', objectKey: 'activeDevice' },
            { value: ['displayName', 'displayOrganization'], dataType: 'adminDemographic', subModuleType: 'CHIP' },
          ],
          tableDatas: [],
        };
      }
      this.getAllUsers();
    }
  }

  scrollToBottom(event: any) {
    if(this.tableConfig.tableDatas.length !== this.totalDataCount){
      if(event.scrolledToBottom){
        this.migrateEndUsersForm.controls['offset'].setValue(this.migrateEndUsersForm.value.offset + 1);
        this.getAllUsers();
      }
    }
  }  

  openConfirmationModal() {
    const config = this._nightWebUtil.shallowCopy(this.config);
    config.data.confirmButtonLabel = 'Continue';
    config.data.title = 'Migrate End Users';
    config.modalClass = 'modal-md';
    config.data.body = `
                        <p class='fw-bold'>Are you sure you want to migrate ${this.selectedUsers.length} End User${this.selectedUsers.length > 1 ? 's' : ''}?</p>
                        <small>"Note: Please note that this action cannot be undone. Before continuing, ensure that the new NightWatch App and WatchFace have been installed on the device of the selected End User${this.selectedUsers.length > 1 ? 's' : ''}. Are you sure you want to migrate selected End Users?"</small>
                      `;
    this.confirmationModalRef = this._modalService.open(ConfirmationModalComponent, config);
    this.confirmationModalRef.onClose.subscribe((data: any) => {
      if (data) {
        this.isLoading.migration = true;
        this._migrationService.migrate(this.selectedUsers)
          .pipe()
          .subscribe({
            next: response => {
              if (response.statusCode == STATUS_CODES.OK) {
                this.selectedUsers.forEach((userId: string) => {
                  const index = this.tableConfig.tableDatas.findIndex(data => data.id === userId);
                  this.tableConfig.tableDatas[index] = { ...this.tableConfig.tableDatas[index], isProcessing: true }
                });
                setTimeout(() => {
                  if (this.selectedUsers.length) {
                    this.selectedUsers.forEach((userId: string) => {
                      const index = this.tableConfig.tableDatas.findIndex(data => data.id === userId);
                      this.tableConfig.tableDatas[index] = { ...this.tableConfig.tableDatas[index], isProcessing: false, demographic: { ...this.tableConfig.tableDatas[index].demographic, status: MESSAGE_CONSTANTS.MIGRATION_FAILED } };
                    });
                    this._toasterService.show("Warning", `Migration failed for ${this.selectedUsers.length} End-User${this.selectedUsers.length > 1 ? 's' : ''}`);
                    this.isLoading.migration = false;
                    this.selectedUsers.length = 0;
                  };
                }, NIGHTWEB_APP_CONSTANT.migrationTimeout);
              };
            },
            error: error => console.log('error migrating end users', error)
          });
      };
    });
  }

  receiveEvent(event: any) {
    event.event.target.checked ? this.selectedUsers.push(event.object.id) : (this.selectedUsers = this.selectedUsers.filter(id => id !== event.object.id));
  }
}
