import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { MdbPopconfirmRef, MdbPopconfirmService } from 'mdb-angular-ui-kit/popconfirm';
import { finalize, Subscription } from 'rxjs';
import { END_USER_STATUS, MESSAGE_CONSTANTS, NIGHTWEB_APP_CONSTANT, USER_STATUS, STATUS_CODES } from 'src/app/constants';
import { AdminService, DataService, NotificationService, ToasterService, UserService } from '../../services';
import { DateUtility, NightwebUtil } from 'src/app/utils';
import { PopConfirmComponent } from '../common';
import { TableConfig } from '../common/interface';
import { AddEditUserModalComponent, ConfigurationModalComponent, ConfirmationModalComponent, LinkDeviceModalComponent } from '../modals';

@Component({
  selector: 'app-end-user',
  templateUrl: './end-user.component.html',
  styleUrls: ['./end-user.component.scss']
})
export class EndUserComponent implements OnInit {

  endUserForm!: FormGroup;

  loading: boolean = false;
  submitted: boolean = false;
  userStatus = USER_STATUS;
  endUsers: any[] = [];
  searchKeyword: string = '';
  userStatusList = END_USER_STATUS;
  phoneMask = NIGHTWEB_APP_CONSTANT.phoneMask
  config = NIGHTWEB_APP_CONSTANT.modalConfig;
  confirmPopConfig = NIGHTWEB_APP_CONSTANT.confirmPopConfig;
  userAddEditComponent!: MdbModalRef<AddEditUserModalComponent>;
  linkDeviceComponent!: MdbModalRef<LinkDeviceModalComponent>;
  configurationModalConfig!: MdbModalRef<ConfigurationModalComponent>;
  confirmationModalRef!: MdbModalRef<ConfirmationModalComponent>;
  popconfirmRef: MdbPopconfirmRef<PopConfirmComponent> | null = null;
  limits = NIGHTWEB_APP_CONSTANT.showLimits;
  defaultDateFormat = NIGHTWEB_APP_CONSTANT.defaultDateFormat;
  Object = Object;
  isWriteAccessible: boolean = this.nightWebUtil.isAccessible('End Users', 'W');
  isSuperAdmin: boolean = this.nightWebUtil.isSuperAdmin();
  filter: any;
  tableConfig: TableConfig = {
    headerElements: [
      'Name', 'Contact', 'Address', 'Link Device', 'Status', 'Last Updated', ''
    ],
    bodyElements: [
      { value: 'name', navigation: true },
      { value: 'contact', navigation: true },
      { value: 'address', dataType: 'address', navigation: true },
      { value: '', actionButton: true },
    ],
    tableDatas: [],
    actionItems: [
      { name: 'Edit', icon: 'pencil-square', eventName: 'editModule' },
      { name: 'Delete', icon: 'trash', eventName: 'deleteModule' }
    ]
  };
  dateFormat = NIGHTWEB_APP_CONSTANT.mdbDefaultDateFormat;
  subscription!: Subscription;
  totalDataCount: number = 0;
  advocates = [];
  defaultLimitSelectWidth: string = NIGHTWEB_APP_CONSTANT.defaultLimitSelectWidth;
  useTrim: boolean = false;
  updatedEndUser: any;

  @ViewChild('tableElement', { static: false }) public tableElement!: ElementRef;

  @HostListener('window:scroll', ['$event'])
  onScroll(event: any): void {
    const nativeElement = this.tableElement.nativeElement;
    const height = Math.round(event.target.clientHeight + nativeElement.scrollTop + 1);
    if (height >= nativeElement.scrollHeight) {
      this.onScrollToBottom({ scrolledToBottom: true });
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.useTrim = this.nightWebUtil.checkScreenSize();
  }

  constructor(
    private dataService: DataService,
    private formBuilder: FormBuilder,
    private dateUtility: DateUtility,
    private userService: UserService,
    private nightWebUtil: NightwebUtil,
    private modalService: MdbModalService,
    private toasterService: ToasterService,
    private popconfirmService: MdbPopconfirmService,
    private adminService: AdminService,
    private _notificationService: NotificationService
  ) {
    this.setEndUserForm();
  }

  ngOnInit(): void {
    this.useTrim = this.nightWebUtil.checkScreenSize();
    this.filter = this.nightWebUtil.getFilterParams(this.endUserForm.value);
    const loggedInUser = this.nightWebUtil.decodeToken(this.nightWebUtil.getLoggedInUser());
    loggedInUser.organization.role.code === 'ADVOCATE' ? this.receiveSelectedValue({ controlName: 'advocateId', value: loggedInUser.id }) : this.getAllEndUsers(this.filter);
    this.dataService.getData().subscribe(
      (data) => {
        if (data) {
          // if(data.endUserId) {
          //   const index = this.endUsers.findIndex(user => user.id === data.endUserId);
          //   this.endUsers[index].status = 'In Progress';
          // } else this.getAllEndUsers(this.filter);
          this.getAllEndUsers(this.filter)
        }
      }
    );
    this._notificationService
    .listen('end-user-state-update')
    .subscribe({
      next: response => {
        if (response?.data) {
          const index = this.endUsers.findIndex(user => user.id === response.data.userId);
          index > -1 && (this.endUsers[index].currentState = response.data.currentState);
        };
      },
      error: error => console.log('error getting updated state status', error)
    });
    this._notificationService
    .listen('device-link-state')
    .subscribe({
      next: response => {
        if (response?.data) {
          const index = this.endUsers.findIndex(user => user.id === response.data.userId);
          index > -1 && (this.endUsers[index].status = response.data.status);
        };
      },
      error: error => console.log('error getting updated state status', error)
    });
    this.getAdvocates();
  }

  setEndUserForm() {
    this.endUserForm = this.formBuilder.group({
      firstName: [''],
      lastName: [''],
      phone: [''],
      email: ['', Validators.pattern(NIGHTWEB_APP_CONSTANT.emailPattern)],
      status: [''],
      keyword: [''],
      sourceOfRequest: [''],
      createdFrom: [''],
      createdTo: [''],
      limit: [NIGHTWEB_APP_CONSTANT.defaultLimit],
      offset: [NIGHTWEB_APP_CONSTANT.defaultOffset],
      advocateId: [null]
    });
  }

  getAllEndUsers(filter?: any) {
    if(filter){
      this.loading = filter.offset === NIGHTWEB_APP_CONSTANT.defaultOffset;
      filter.offset === NIGHTWEB_APP_CONSTANT.defaultOffset && (this.endUsers = []);
    } else {
      this.loading = true;
      this.endUsers = [];
    };
    this.userService.getAllEndUsers(filter)
      .pipe(finalize(() =>{
        this.loading = false;
      }))
      .subscribe({
        next: (response) => {
          this.totalDataCount = response.data.count;
          this.endUsers = filter?.offset !== NIGHTWEB_APP_CONSTANT.defaultOffset ? ([...this.endUsers, ...response.data.rows]) : response.data.rows;;
          // map address to object of object if the response is in array of objects
          this.endUsers = this.endUsers.map((user: any) => {
            if (user?.demographic?.address.length) {
              const [address] = user?.demographic?.address;
              user.demographic.address = {
                home: address
              }
            }
            return user;
          });
        },
        error: (e) => {
        }
      })
  }

  openAddEditUserModal(user?: any) {
    const config = this.nightWebUtil.shallowCopy(this.config);
    config.data.title = user ? 'Edit' : 'Add';
    config.modalClass = 'modal-dialog-scrollable modal-md';
    config.data.formData = user;
    this.userAddEditComponent = this.modalService.open(AddEditUserModalComponent, config);
    this.userAddEditComponent.onClose.subscribe((data: any) => {
      if (data) {
        if (data.updatedData) {
          const index = this.endUsers.findIndex(user => user.id === data.updatedData.id);
          this.endUsers[index] = { ...this.endUsers[index], ...data.updatedData };
        } else this.endUsers.unshift(data);
      };
    });
  }

  openLinkDeviceModal(user?: any) {
    const config = this.nightWebUtil.shallowCopy(this.config);
    config.data.title = 'Link Device';
    config.modalClass = 'modal-dialog-scrollable modal-md';
    config.data.formData = user;
    config.data.formData.userId = user.id;
    config.data.formData.configurations = user.configurations;
    config.data.confirmButtonLabel = 'Link Device';
    this.linkDeviceComponent = this.modalService.open(LinkDeviceModalComponent, config);
    this.linkDeviceComponent.onClose.subscribe((data: any) => {
      if (data) {
        const index = this.endUsers.findIndex(user => user.id === data.id);
        this.endUsers[index] = { ...this.endUsers[index], advocate: data.advocate, advocateId: data.advocateId, safeWord: data.safeWord };
        this.updatedEndUser = this.endUsers[index];
      }
    });
  }

  openUnlinkDeviceModal(user?: any) {
    const config = this.nightWebUtil.shallowCopy(this.config);
    config.data.confirmButtonLabel = 'Unlink';
    config.data.formData = user;
    config.modalClass = 'modal-md';
    config.data.title = `Unlink Device`;
    config.data.body = `Are you sure you want to unlink <strong>${user?.demographic?.firstName + ' ' + user?.demographic?.middleName + ' ' + user?.demographic?.lastName}</strong>?`;
    this.confirmationModalRef = this.modalService.open(ConfirmationModalComponent, config);
    this.confirmationModalRef.onClose.subscribe((data: any) => {
      if (data) {
        const unlink = {
          deviceId: data?.activeDevice?.deviceId,
          endUserId: data?.id,
          reason: ''
        }
        this.unlinkDevice(unlink);
      }
    });
  }

  openEditConfigurationModal(user?: any) {
    const config = this.nightWebUtil.shallowCopy(this.config);
    config.data.title = 'Configuration';
    config.modalClass = 'modal-dialog-scrollable modal-md';
    config.data.formData = user;
    config.data.formData.mode = 'edit';
    config.data.confirmButtonLabel = 'Update';
    config.data.formData.userId = user.id ?? this.updatedEndUser.id;
    config.data.formData.module = 'user';
    config.data.formData.deviceId = user.activeDevice.deviceId;
    this.configurationModalConfig = this.modalService.open(ConfigurationModalComponent, config);
    this.configurationModalConfig.onClose.subscribe((data: any) => {
      if (data) {
        // this.getAllEndUsers();
      }
    });
  }

  unlinkDevice(data: any) {
    this.userService.unLinkDevice(data)
      .subscribe({
        next: (response) => {
          const index = this.endUsers.findIndex(user => user.id === response.data.endUserId);
          this.endUsers[index] = { ...this.endUsers[index], activeDevice: null, status: "New"};
          this.toasterService.show('Success', MESSAGE_CONSTANTS.DEVICE_UNLINKED);
        },
        error: (e) => {
        }
      })
  }

  deleteUser(event: Event, user: any) {
    const target = event.target as HTMLElement;
    const config = this.nightWebUtil.shallowCopy(this.confirmPopConfig);
    config.data.confirmButtonLabel = 'Delete';
    config.data.title = `Delete`;
    config.data.body = MESSAGE_CONSTANTS.DELETE_CONFIRMATION;
    this.popconfirmRef = this.popconfirmService.open(PopConfirmComponent, target, config);
    this.popconfirmRef.onConfirm.subscribe(() => {
      this.userService.delete(user.id)
        .subscribe({
          next: (response) => {
            this.endUsers.splice(this.endUsers.findIndex(x => x.id == user.id), 1);
            this.toasterService.show("Success", MESSAGE_CONSTANTS.USER_REMOVED);
          },
          error: (e) => {
          }
        })
    });
  }

  search() {
    if (this.endUserForm.valid) {
      this.endUserForm.controls['offset'].setValue(NIGHTWEB_APP_CONSTANT.defaultOffset);
      this.filter = this.endUserForm.value;
      this.filter = this.nightWebUtil.shallowCopy(this.endUserForm.value);
      this.filter.createdFrom = this.dateUtility.formatDate(this.filter.createdFrom);
      this.filter.createdTo = this.dateUtility.formatDate(this.filter.createdTo);
      this.filter.phone = this.filter.phone.toString();
      this.filter = this.nightWebUtil.getFilterParams(this.filter);
      this.getAllEndUsers(this.filter);
    }
  }

  clear() {
    this.setEndUserForm();
    this.filter = this.nightWebUtil.getFilterParams(this.endUserForm.value)
    this.getAllEndUsers(this.filter);
  }

  getMaxDate() {
    return this.dateUtility.getMaxCreatedFromDate();
  }

  getMinDate() {
    return this.dateUtility.getMinCreatedToDate((this.endUserForm.controls['createdFrom']?.value));
  }

  getFiltered() {
    // let filterParams: any = this.nightWebUtil.getFilterParams(this.endUserForm.value);
    // if (filterParams?.createdFrom) {
    //   filterParams.createdFrom = this.dateUtility.convertToMMddYYYY(filterParams?.createdFrom);
    // }
    // if (filterParams?.createdTo) {
    //   filterParams.createdTo = this.dateUtility.convertToMMddYYYY(filterParams?.createdTo);
    // }  
  }
  
  receiveSelectedValue(event: any) {
    if (event) {
      this.endUserForm.patchValue({
        [event.controlName]: event.value
      });
      if (event.controlName === 'limit' || event.controlName === 'advocateId') {
        this.endUserForm.controls['offset'].setValue(NIGHTWEB_APP_CONSTANT.defaultOffset);
        this.filter = this.nightWebUtil.getFilterParams(this.endUserForm.value);
        const {createdFrom, createdTo} = this.filter;
        createdFrom && (this.filter.createdFrom = this.dateUtility.formatDate(createdFrom));
        createdTo && (this.filter.createdTo = this.dateUtility.formatDate(createdTo));
        this.getAllEndUsers(this.filter);
      };
    };
  }
  
  getAdvocates(){
    this.adminService.getAdvocates()
      .pipe(finalize(() => { }))
        .subscribe({
          next: (response:any) => {
            if(response.statusCode == STATUS_CODES.OK){
              this.advocates = response.data.rows.map((advocate: any) => {
                const { firstName, lastName } = advocate.demographic;
                return {
                  label: `${firstName} ${lastName}`,
                  value: advocate.id
                };
              });
            };
          }, error: (error) => {
            console.log('error', error);
            this.toasterService.showError(error);
          }
        });
  }

  receiveEvent({ action, event, object }: any) {
    if (action === 'edit') {
      this.openAddEditUserModal(object);
    } else if (action === 'delete') {
      this.deleteUser(event, object);
    }
  }

  receiveSearchKeyword(keyword: any) {
    this.endUserForm.controls['offset'].setValue(NIGHTWEB_APP_CONSTANT.defaultOffset);
    this.searchKeyword = keyword;
    this.endUserForm.patchValue({ keyword });
    this.filter = this.nightWebUtil.getFilterParams(this.endUserForm.value);
    const {createdFrom, createdTo} = this.filter;
    createdFrom && (this.filter.createdFrom = this.dateUtility.convertToMMddYYYY(createdFrom));
    createdTo && (this.filter.createdTo = this.dateUtility.convertToMMddYYYY(createdTo));
    this.getAllEndUsers(this.filter);
  }

  onScrollToBottom(event: any) {
    if(this.endUsers.length !== this.totalDataCount){
      if(event.scrolledToBottom){
        this.endUserForm.controls['offset'].setValue(this.endUserForm.value.offset + 1);
        this.filter.offset = (this.endUserForm.value.offset);
        this.filter = this.nightWebUtil.getFilterParams(this.filter);
        const {createdFrom, createdTo} = this.filter;
        createdFrom && (this.filter.createdFrom = this.dateUtility.convertToMMddYYYY(createdFrom));
        createdTo && (this.filter.createdTo = this.dateUtility.convertToMMddYYYY(createdTo));
        this.getAllEndUsers(this.filter);
      }
    }
  }

  clearDatePicked(type: string) {
    this.endUserForm.controls[type].setValue(null);
  }
}
