import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'trim'
})

export class TrimCharacters implements PipeTransform {
    useTrim = false;

    transform(value: string, limit: number, useTrim: boolean): string {
        if (useTrim && value.length > limit) {
          return value.substring(0, limit) + '...';
        }
        return value;
    }
}
