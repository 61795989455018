import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize, forkJoin } from 'rxjs';
import {
  NIGHTWEB_APP_CONSTANT,
  NODATA_MESSAGES,
  USER_STATUS,
  STATUS_CODES,
  API_CONSTANTS,
  MESSAGE_CONSTANTS,
} from '../../constants';
import {
  DeviceService,
  DownloadService,
  NotificationService,
  ToasterService,
  UserService,
} from '../../services';
import { DateUtility, NightwebUtil } from '../../utils';
import { TableConfig } from '../common/interface';
import { NotesComponent } from '../common';
import * as moment from 'moment';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss'],
})
export class UserDetailComponent implements OnInit {
  userDetailForm!: FormGroup;
  submitted: boolean = false;
  userId!: string;
  user!: any;
  userCheckinData: any[] = [];
  loading: boolean = false;
  limits = NIGHTWEB_APP_CONSTANT.showLimits;
  skeletonTheme = NIGHTWEB_APP_CONSTANT.skeletonLoaderConfig;
  dateFormat = NIGHTWEB_APP_CONSTANT.defaultDateFormat;
  mdbDateFormat = NIGHTWEB_APP_CONSTANT.mdbDefaultDateFormat;
  userStatus = USER_STATUS;
  isLoading = {
    notes: false,
    removeEmergencyCadence: false,
    syncAssociatedPhoneNumber: false,
    syncAccountNumber: false,
    gpsCheck: false,
    networkCheck: false,
  };
  filter: any;
  userInitials: string = '';
  totalDataCount: number = 0;
  tableConfig: TableConfig = {
    headerElements: [
      'Emergency',
      'Packet Sequence',
      'Watch Check-In',
      'Received Check-In',
      'Expected Check-In',
      'Deviation',
      'GPS',
      'Heart Rate',
      'Signal Strength',
      'LTE Strength',
      'Battery Status',
      'Charging',
      'On Wrist',
    ],
    bodyElements: [
      { value: 'isEmergencyState', dataType: 'boolean' },
      { value: 'packetSequence', dataType: 'float' },
      { value: 'checkInTime', dataType: 'created' },
      { value: 'createdAt', dataType: 'created' },
      { value: 'expected', dataType: 'created' },
      { value: 'deviation', dataType: 'string' },
      { value: 'latLong' },
      { value: 'heartRate', concatString: ' bpm' },
      { value: 'signalStrength', concatString: ' bar' },
      { value: 'lteSignalStrength', dataType: 'lteStrength' },
      { value: 'batteryStatus', dataType: 'percent' },
      { value: 'isCharging', dataType: 'boolean' },
      { value: 'isOnWrist', dataType: 'boolean' },
    ],
    tableDatas: [],
    actionItems: [
      { name: 'Edit', eventName: 'editModule' },
      { name: 'Link Configuration', eventName: 'configureModule' },
      { name: 'Delete', eventName: 'deleteModule' },
    ],
  };
  notFoundMessage = NODATA_MESSAGES.NO_USER_ACTIVITIES;
  mapConstants = [
    {
      icon: '<i class="fas fa-map-marker-alt text-danger"></i>',
      label: 'Event Location',
    },
    {
      icon: '<i class="fas fa-map-marker-alt text-primary"></i>',
      label: 'Current/Last Location',
    },
    { icon: '<i class="fas fa-circle"></i>', label: 'GPS Tracking Points' },
    {
      icon: '<span class="fa-path"><b>|</b></span>',
      label: 'GPS Tracking Path',
    },
  ];
  recordings = [
    { name: 'Recording_ 01234.mp3' },
    { name: 'Recording_ 01234.mp3' },
    { name: 'Recording_ 01234.mp3' },
    { name: 'Recording_ 01234.mp3' },
    { name: 'Recording_ 01234.mp3' },
    { name: 'Recording_ 01234.mp3' },
    { name: 'Recording_ 01234.mp3' },
    { name: 'Recording_ 01234.mp3' },
    { name: 'Recording_ 01234.mp3' },
  ];
  mapFeatures: any;
  customMarkers: number[] = [];
  emergencyTriggeredLocations: any = {
    indexes: [],
    icon: '',
  };
  defaultLimitSelectWidth: string =
    NIGHTWEB_APP_CONSTANT.defaultLimitSelectWidth;
  isToggleNotes: boolean = false;
  notes: any[] = [];
  versionOneWatchEliminatedColumns = [
    { label: 'Packet Sequence', value: 'packetSequence' },
    { label: 'Server Time', value: 'expected' },
    { label: 'Deviation', value: 'deviation' },
  ];
  useTrim: boolean = false;
  device = {
    gps: { status: '', date: '' },
    network: { status: '', date: '' },
  };
  deviceId!: string;

  @ViewChild(NotesComponent) notesComponent!: NotesComponent;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dateUtil: DateUtility,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private nightwebUtil: NightwebUtil,
    private toasterService: ToasterService,
    private _notificationService: NotificationService,
    private _nightWebUtil: NightwebUtil,
    private _deviceService: DeviceService,
    private _downloadService: DownloadService
  ) {
    this.setuserDetailForm();
    this.route.params.subscribe((params) => {
      if (params['id']) {
        this.userId = params['id'];
        this.fetchUserAndUserActivity();
        this.getLocations(params['id']);
        this.getNotes(params['id']);
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.useTrim = this._nightWebUtil.checkScreenSize();
  }

  ngOnInit(): void {
    this._notificationService
      .listen('new-check-in-data')
      .subscribe((response: any) => {
        const userId = this.router.url.replace(
          `${API_CONSTANTS.END_USERS}/`,
          ''
        );
        if (response && userId === response.userId) {
          const index = this.tableConfig.tableDatas.findIndex(
            ({ packetSequence }) =>
              packetSequence === response.data.packetSequence
          );

          if (index > -1) {
            this.tableConfig.tableDatas[index] = {
              ...response.data,
              latLong: this.concatLatLong(response.data.gps),
            };
          } else
            this.userCheckinData.unshift({
              ...response.data,
              latLong: this.concatLatLong(response.data.gps),
            });
        }
      });
    this.useTrim = this._nightWebUtil.checkScreenSize();
  }

  getLocations(userId: string) {
    this.userService
      .getLocations(userId)
      .pipe()
      .subscribe((response: any) => {
        if (response.statusCode == STATUS_CODES.OK) {
          this.mapFeatures = response.data;
          this.emergencyTriggeredLocations.indexes =
            this.mapFeatures.rows.reduce(
              (accumulator: number[], current: any, index: number) => {
                if (current.isEmergencyState) {
                  accumulator.push(index);
                }
                return accumulator;
              },
              []
            );
          this.emergencyTriggeredLocations.indexes.length &&
            (this.emergencyTriggeredLocations.icon =
              '../../../../assets/img/png/red-marker.png');
        }
      });
  }

  setuserDetailForm() {
    this.userDetailForm = this.formBuilder.group({
      keyword: [],
      limit: [25],
      offset: [NIGHTWEB_APP_CONSTANT.defaultOffset],
      createdFrom: [],
      createdTo: [],
    });
  }

  fetchUserAndUserActivity() {
    this.loading = true;
    forkJoin([
      this.userService.getUserById(this.userId),
      this.userService.getUserActivities(this.userId, {
        localTime: moment(new Date()).format('YYYY/MM/DD'),
        limit: 25,
        offset: NIGHTWEB_APP_CONSTANT.defaultOffset,
      }),
    ])
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe({
        next: (response) => {
          const [userDetail, checkInData] = response;
          this.user = {
            ...userDetail.data,
            activeDevice: {
              ...userDetail.data.activeDevice,
              info: {
                ...userDetail.data.activeDevice?.info,
                modelAndSerialNumber: `${
                  userDetail.data?.activeDevice?.info?.modelNumber || ''
                } / ${userDetail.data?.activeDevice?.info?.serialNumber || ''}`,
              },
            },
            demographic: {
              ...userDetail.data.demographic,
              fullName: `${userDetail.data?.demographic?.firstName ?? ''} ${
                userDetail.data?.demographic?.middleName ?? ''
              } ${userDetail.data?.demographic?.lastName ?? ''}`,
            },
          };
          this.deviceId = this.user.activeDevice.deviceId;
          this.getDeviceGps();
          this.getDeviceNetwork();
          if (this.user.watchAppVersion == 1) {
            this.tableConfig.headerElements =
              this.tableConfig.headerElements.filter((header) => {
                return !this.versionOneWatchEliminatedColumns.some(
                  (item) => item.label === header
                );
              });
            this.tableConfig.bodyElements =
              this.tableConfig.bodyElements.filter((body: any) => {
                return !this.versionOneWatchEliminatedColumns.some(
                  (item) => item.value === body.value
                );
              });
          }
          this.userCheckinData = checkInData.data;
          if (this.user?.demographic?.address.length) {
            const [address] = this.user?.demographic?.address;
            if (this.user?.demographic?.address) {
              this.user.demographic.address = {
                home: address,
              };
            }
          }
          this.getUserInitials(this.user?.demographic);
          this.userCheckinData = checkInData.data.rows.map((checkIn: any) => {
            return {
              ...checkIn,
              latLong: this.concatLatLong(checkIn?.gps),
            };
          });
          this.tableConfig.tableDatas = this.userCheckinData;
        },
      });
  }

  getUserActivities(filter: any) {
    this.loading = filter.offset === NIGHTWEB_APP_CONSTANT.defaultOffset;
    filter.offset === NIGHTWEB_APP_CONSTANT.defaultOffset &&
      (this.tableConfig.tableDatas = []);
    this.userService
      .getUserActivities(this.userId, filter)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe({
        next: (response) => {
          this.totalDataCount = response.data.count;
          this.getUserInitials(this.user?.demographic);
          this.userCheckinData =
            filter.offset !== NIGHTWEB_APP_CONSTANT.defaultOffset
              ? [...this.userCheckinData, ...response.data.rows]
              : response.data.rows;
          this.tableConfig.tableDatas = this.userCheckinData.map(
            (checkIn: any) => {
              return {
                ...checkIn,
                latLong: this.concatLatLong(checkIn?.gps),
              };
            }
          );
        },
        error: (error) => {
          console.log('error', error);
          this.toasterService.showError(error);
        },
      });
  }

  getUserInitials(user: any) {
    this.userInitials = this.nightwebUtil.getInitials(user);
  }

  concatLatLong(gps: any) {
    if (gps && (gps?.latitude == 'null' || gps?.longitude == 'null')) {
      return '';
    }
    if (gps && gps?.latitude && gps?.longitude) {
      return gps.latitude + ', ' + gps.longitude;
    } else if (gps && (gps?.latitude == 'null' || gps?.longitude == 'null')) {
      return '';
    } else {
      return '';
    }
  }

  checkDateIsBefore() {
    const isBefore = this.dateUtil.checkIsBefore(
      this.userDetailForm.controls['createdFrom']?.value,
      this.userDetailForm.controls['createdTo']?.value
    );
    return isBefore;
  }

  getMaxDate() {
    return this.dateUtil.getMaxCreatedFromDate();
  }

  getMinDate() {
    return this.dateUtil.getMinCreatedToDate(
      this.userDetailForm.controls['createdFrom']?.value
    );
  }

  getFiltered(isRefreshed: boolean = false, dateSelected: boolean = false) {
    if (isRefreshed) {
      this.userDetailForm.controls['offset'].setValue(
        NIGHTWEB_APP_CONSTANT.defaultOffset
      );
      if (!dateSelected) {
        this.userDetailForm.patchValue({
          limit: 25,
          createdFrom: null,
          createdTo: null,
        });
      }
    }
    let filterParams: any = this.nightwebUtil.getFilterParams(
      this.userDetailForm.value
    );
    if (filterParams?.createdFrom) {
      filterParams.createdFrom = this.dateUtil.convertToMMddYYYY(
        filterParams?.createdFrom
      );
    }
    if (filterParams?.createdTo) {
      filterParams.createdTo = this.dateUtil.convertToMMddYYYY(
        filterParams?.createdTo
      );
    }
    this.getUserActivities(filterParams);
  }

  receiveEvent(event: any) {}

  receiveSelectedValue(event: any) {
    this.userDetailForm.patchValue({
      [event.controlName]: event.value ?? '',
    });
    if (event.controlName === 'limit') {
      this.userDetailForm.controls['offset'].setValue(
        NIGHTWEB_APP_CONSTANT.defaultOffset
      );
      this.getFiltered();
    }
  }

  scrollToBottom(event: any) {
    if (this.userCheckinData.length !== this.totalDataCount) {
      if (event.scrolledToBottom) {
        this.userDetailForm.controls['offset'].setValue(
          this.userDetailForm.value.offset + 1
        );
        this.getFiltered();
      }
    }
  }

  clearDatePicked(type: string) {
    this.userDetailForm.controls[type].setValue(null);
    this.getFiltered();
  }

  getNotes(id: string) {
    this.isLoading.notes = true;
    this.userService
      .getNotes(id)
      .pipe(
        finalize(() => {
          this.isLoading.notes = false;
        })
      )
      .subscribe({
        next: (response) => {
          if (response.statusCode == STATUS_CODES.OK) {
            this.notes = response.data.rows;
          }
        },
        error: (error) => {
          console.log('error', error);
          this.toasterService.showError(error);
        },
      });
  }

  onRemoveCadence() {
    this.isLoading.removeEmergencyCadence = true;
    this.userService
      .removeCadence(this.userId)
      .pipe(
        finalize(() => {
          this.isLoading.removeEmergencyCadence = false;
        })
      )
      .subscribe({
        next: (response) => {
          if (response.statusCode == STATUS_CODES.OK) {
            this.toasterService.show(
              'SUCCESS',
              MESSAGE_CONSTANTS.REMOVE_EMERGENCY_CADENCE_SUCCESS
            );
          }
        },
        error: (error) =>
          console.log('error removing emergency cadence', error),
      });
  }

  onSyncAssociatedPhoneInRapid() {
    this.isLoading.syncAssociatedPhoneNumber = true;
    this.userService
      .syncAssociatedPhoneInRapid(this.userId)
      .pipe(
        finalize(() => {
          this.isLoading.syncAssociatedPhoneNumber = false;
        })
      )
      .subscribe({
        next: (response) => {
          if (response.statusCode == STATUS_CODES.OK) {
            this.toasterService.show(
              'SUCCESS',
              MESSAGE_CONSTANTS.ASSOCIATED_PHONE_SYNC_RAPID_SUCCESS
            );
          }
        },
        error: (error) => console.log('error syncing phone in rapid', error),
      });
  }

  onSyncAccountNumberInRapid() {
    this.isLoading.syncAccountNumber = true;
    this.userService
      .syncAccountNumberInRapid(this.userId)
      .pipe(
        finalize(() => {
          this.isLoading.syncAccountNumber = false;
        })
      )
      .subscribe({
        next: (response) => {
          if (response.statusCode == STATUS_CODES.OK) {
            this.toasterService.show(
              'SUCCESS',
              MESSAGE_CONSTANTS.ACCOUNT_NUMBER_SYNC_RAPID_SUCCESS
            );
          }
        },
        error: (error) =>
          console.log('error syncing account number in rapid', error),
      });
  }

  getDeviceGps(isPinged: boolean = false) {
    this._deviceService
      .getDeviceGps(this.deviceId)
      .pipe(
        finalize(() => {
          this.isLoading.gpsCheck = false;
        })
      )
      .subscribe({
        next: (response) => {
          if (response.statusCode == STATUS_CODES.OK) {
            this.device.gps = {
              ...response.data,
              date:
                response?.data?.date !== 'N/A'
                  ? moment(response.data.date).format('MM/DD/yyyy hh:mm a')
                  : 'N/A',
            };
            isPinged &&
              this.toasterService.show(
                'SUCCESS',
                MESSAGE_CONSTANTS.CHECK_GPS_SUCCESS
              );
          }
        },
      });
  }

  getDeviceNetwork(isPinged: boolean = false) {
    this._deviceService
      .getDeviceNetwork(this.deviceId)
      .pipe(
        finalize(() => {
          this.isLoading.networkCheck = false;
        })
      )
      .subscribe({
        next: (response) => {
          if (response.statusCode == STATUS_CODES.OK) {
            this.device.network = {
              ...response.data,
              date:
                response?.data?.date !== 'N/A'
                  ? moment(response.data.date).format('MM/DD/yyyy hh:mm a')
                  : 'N/A',
            };
            isPinged &&
              this.toasterService.show(
                'SUCCESS',
                MESSAGE_CONSTANTS.CHECK_NETWORK_SUCCESS
              );
          }
        },
      });
  }

  onGpsCheck() {
    this.isLoading.gpsCheck = true;
    this._deviceService
      .pingGps(this.deviceId)
      .pipe()
      .subscribe({
        next: (response) => {
          if (response.statusCode == STATUS_CODES.OK) {
            setTimeout(() => {
              this.getDeviceGps(true);
            }, 120000);
          }
        },
        error: (error) => console.log('error getting pinged gps', error),
      });
  }

  onNetworkCheck() {
    this.isLoading.networkCheck = true;
    this._deviceService
      .pingNetwork(this.deviceId)
      .pipe()
      .subscribe({
        next: (response) => {
          if (response.statusCode == STATUS_CODES.OK) {
            setTimeout(() => {
              this.getDeviceNetwork(true);
            }, 120000);
          }
        },
        error: (error) => console.log('error getting pinged network', error),
      });
  }

  onDownloadLogs() {
    this._deviceService
      .getWatchStatistics(this.deviceId)
      .pipe()
      .subscribe({
        next: (response) => {
          if (response.statusCode == STATUS_CODES.OK) {
            this._downloadService.downloadJson(
              response.data,
              this.user.activeDevice.info.imeiNumber
            );
          };
        },
        error: (error) => console.log('error getting watch statistics', error),
      });
  }

  onDownloadRRMSLogs() {
    this._deviceService
      .getRRMSLogs(this.user.accountNumber)
      .pipe()
      .subscribe({
        next: (response) => {
          if (response.statusCode == STATUS_CODES.OK) {
            this._downloadService.downloadJson(
              response.data.rows,
              `RRMS-Logs_${this.user.accountNumber}`
            );
          };
        },
        error: (error) => console.log('error getting RRMS logs', error),
      });
  }

  onEmergencyClear() {
    console.log('date', new Date().toISOString());
    const body = {
      AlarmFlag: false,
      AlarmNumOrTest: "93422",
      AreaDescription: null,
      AreaNum: null,
      DevNum: 50548615,
      EventCode: null,
      EventOpAct: "Dial Phone",
      FullClearFlag: false,
      HistoryCommentBig: null,
      HistoryDate: new Date().toISOString(),
      HistorySeqNum: 860740074,
      Phone: '336-272-7056',
      Point: null,
      PointDescription: null,
      ScheduledDate: null,
      ServiceTypeDescription: "",
      ShortName: "SJP",
      SigForm: null,
      SignalCode: null,
      SignalCodeDescription: null,
      SiteNum: 50546999,
      TestAuthorization: null,
      TestDetails: null,
      TestEffectiveDate: null,
      TestExpireDate: null,
      TransmitterCode: this.user.accountNumber,
      UTCDate: new Date().toISOString(),
      AlarmDisposition: null,
      RawMessage: null,
      OpAct: "REST",
      AlarmNum: 859342226
    };
    this._deviceService
      .clearEmergency(body)
      .pipe(finalize(() => { this.toasterService.show('Success', MESSAGE_CONSTANTS.EMERGENCY_CLEAR_SUCCESS) }))
      .subscribe({
        next: (response) => { },
        error: (error) => console.log('error on clearing emergency', error),
      });
  }
}
