import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { finalize } from 'rxjs';
import { MESSAGE_CONSTANTS, NIGHTWEB_APP_CONSTANT } from '../../constants';
import { AdminService, DataService, RoleService, ToasterService } from '../../services';
import { NightwebUtil } from 'src/app/utils';

@Component({
  selector: 'app-profile-information',
  templateUrl: './profile-information.component.html',
  styleUrls: ['./profile-information.component.scss']
})
export class ProfileInformationComponent implements OnInit {

  profileInformationForm !: FormGroup;

  loading: boolean = false;
  submitted: boolean = false;
  phoneMask = NIGHTWEB_APP_CONSTANT.phoneMask;

  @Input() user!: any;
  @Input() isAdvocate!: boolean;

  constructor(
    private dataService: DataService,
    private formBuilder: FormBuilder,
    private nightwebUtil: NightwebUtil,
    private adminService: AdminService,
    private toasterService: ToasterService
  ) {
    this.setProfileInformationForm()
  }

  ngOnInit(): void {

    // this.dataService.getData().subscribe(
    //   (data) => {
    //     if (data) {
    //       this.profileInformationForm.patchValue(data);
    //     }
    //   }
    // )

  }

  ngOnChanges() {
    if (this.user) {
      this.profileInformationForm.patchValue(this.user);
    }
  }

  get demographic(): FormGroup {
    return this.profileInformationForm.get('demographic') as FormGroup;
  }

  setProfileInformationForm() {
    this.profileInformationForm = this.formBuilder.group({
      userName: ['', Validators.required],
      demographic: this.formBuilder.group({
        firstName: ['', Validators.required],
        middleName: [''],
        lastName: [''],
        gender: [''],
        phone: ['', Validators.required],
        email: [{
          value: this.user?.demographic?.email,
          disabled: true
        }, Validators.required, [Validators.required, Validators.pattern(NIGHTWEB_APP_CONSTANT.emailPattern)]]
      }),
      emergencyNotifications: this.formBuilder.group({
        email: [true],
        sms: [true]
      }),
      warningNotifications: this.formBuilder.group({
        email: [true],
        sms: [true]
      }),
      followUpNotifications: this.formBuilder.group({
        email: [true],
        sms: [true]
      }),
      id: ['', Validators.required]
    })
  }

  save() {
    this.loading = true;
    if (!this.isAdvocate) {
      this.profileInformationForm.controls['emergencyNotifications'].patchValue({ email: false, sms: false });
      this.profileInformationForm.controls['warningNotifications'].patchValue({ email: false, sms: false });
      this.profileInformationForm.controls['followUpNotifications'].patchValue({ email: false, sms: false });
    };
    this.adminService.updateSelf(this.profileInformationForm.getRawValue())
      .pipe(finalize(() => this.loading = false))
      .subscribe({
        next: (response) => {
          this.user = response.data;
          if (response?.data?.token) {
            this.nightwebUtil.setLoggedInUser(response.data.token);
            const user = this.nightwebUtil.decodeToken(response.data.token);
            this.profileInformationForm.patchValue(user);
            this.dataService.sendData({ user, key: 'update-profile' });
          }
          this.toasterService.show('Success', MESSAGE_CONSTANTS.ADMIN_UPDATED);
        },
        error: (e) => {
        }
      })
  }

  cancel() {
    this.profileInformationForm.patchValue(this.user.demographic);
  }

}
