import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { finalize } from 'rxjs';
import { MESSAGE_CONSTANTS, NIGHTWEB_APP_CONSTANT, STATUS_CODES, US_STATES } from 'src/app/constants';
import { AdminService, OrganizationService, ToasterService, UserService } from 'src/app/services';
import { DateUtility, NightwebUtil } from 'src/app/utils';

@Component({
  selector: 'app-add-edit-user-modal',
  templateUrl: './add-edit-user-modal.component.html',
  styleUrls: ['./add-edit-user-modal.component.scss']
})
export class AddEditUserModalComponent implements OnInit {

  userForm!: FormGroup;

  loading = false;
  submitted = false;

  title!: string;
  formData: any = {};
  organizationsList: any[] = [];
  advocateList: any[] = [];
  phoneMask = NIGHTWEB_APP_CONSTANT.phoneMask;
  status_codes = STATUS_CODES;
  states = US_STATES;
  dateFormat = NIGHTWEB_APP_CONSTANT.mdbDefaultDateFormat;
  maxDate = new Date();
  role: string = '';
  genderList: any[] = NIGHTWEB_APP_CONSTANT.genderList;

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private dateUtility: DateUtility,
    private adminService: AdminService,
    private nightwebUtil: NightwebUtil,
    private toasterService: ToasterService,
    private organizationService: OrganizationService,
    public modalRef: MdbModalRef<AddEditUserModalComponent>
  ) {
    this.role = this.nightwebUtil.getRole();
    this.setUserForm();
  }

  get demographic(): FormGroup {
    return this.userForm.get('demographic') as FormGroup;
  }

  get address(): FormGroup {
    return this.userForm.controls['demographic']?.get('address') as FormGroup;
  }

  get home(): FormGroup {
    return this.address?.get('home') as FormGroup;
  }

  ngOnInit(): void {
    this.getAllOrganizations();
    if (this.formData) {
      const { home, work } = this.formData.demographic?.address;
      this.userForm.patchValue({
        ...this.formData
      });
      if (this.formData.organizationId) {
        this.getAdvocates(this.formData.organizationId);
      }
      this.userForm.get('demographic')?.patchValue({
        address: home,
        dob: new Date(this.userForm.get('demographic')?.value?.dob)
      });
    }
  }
  
  setUserForm() {
    this.userForm = this.formBuilder.group({
      id: [],
      demographic: this.formBuilder.group({
        firstName: ['', Validators.required],
        middleName: [''],
        lastName: [''],
        dob: [''],
        gender: ['Male'],
        phone: ['', Validators.required],
        email: ['', [Validators.required, Validators.pattern(NIGHTWEB_APP_CONSTANT.emailPattern)]],
        address: this.formBuilder.group({
          home: this.formBuilder.group({
            addressLine1: ['', Validators.required],
            addressLine2: [''],
            city: ['', Validators.required],
            state: ['', Validators.required],
            zip: ['', [Validators.pattern(NIGHTWEB_APP_CONSTANT.zipPattern)]]
          })
        })
      }),
      status: ['New'],
      safeWord: [''],
      accountNumber: [''],
      relatedPersons: this.formBuilder.array([]),
      organizationId: ['', Validators.required],
      advocateId: [null],
    });
  }

  getAllOrganizations() {
    this.organizationService.getAllOrganizations()
      .pipe(finalize(() => this.loading = false))
      .subscribe({
        next: (response) => {
          this.organizationsList = response.data.rows;
          if (this.organizationsList.length === 1 && !this.formData) {
            this.userForm.patchValue({
              organizationId: this.organizationsList[0]?.id
            });
            this.getAdvocates(this.userForm.get('organizationId')?.value)
          }
        },
        error: (e) => { }
      })
  }

  getAdvocates(organizationId?: string) {
    this.adminService.getAdvocates({ organizationId, fromAdd: true })
      .pipe(finalize(() => this.loading = false))
      .subscribe({
        next: (response) => {
          this.advocateList = response.data.rows.map((admin: any) => {
            admin.displayName = this.nightwebUtil.getDisplayName(admin?.demographic)
            return admin;
          });
          if (this.advocateList.length === 1) {
            this.userForm.patchValue({
              advocateId: this.advocateList[0]?.id
            });
            return;
          };
          if (this.formData?.advocateId) {
            this.userForm.controls['advocateId'].setValue(this.formData.advocateId);
            return;
          };
          this.userForm.controls['advocateId'].setValue(null);
        },
        error: (e) => { }
      })
  }

  save() {
    let user = {
      ...this.userForm.value,
      demographic: {
        ...this.userForm.get('demographic')?.value,
        dob: this.dateUtility.formatDate(this.userForm.get('demographic')?.value.dob, NIGHTWEB_APP_CONSTANT.defaultMomentDateFormat)
      }
    }

    if (this.userForm.valid) {
      this.loading = true;

      if (this.userForm.get('id')?.value) {
        delete user['status'];
        this.userService.update(user)
          .pipe(finalize(() => { this.loading = false }))
          .subscribe({
            next: (response) => {
              if (response.statusCode == this.status_codes.OK) {
                this.modalRef.close(response.data);
                this.toasterService.show('Success', MESSAGE_CONSTANTS.USER_UPDATED);
              };
            },
            error: (e) => {
            }
          });
      } else {
        this.userService.create(user)
          .pipe(finalize(() => { this.loading = false }))
          .subscribe({
            next: (response) => {
              if (response.statusCode == this.status_codes.OK) {
                this.toasterService.show('Success', MESSAGE_CONSTANTS.USER_ADDED);
                this.modalRef.close(response.data);
              }
            },
            error: (e) => {
            }
          });
      };
    };
  }

  confirm() {
    this.modalRef.close();
  }

  close() {
    this.submitted = false;
    this.modalRef.close();
  }

  receiveAutocompleteValue(state: any) {
    this.address.controls['home'].patchValue({ state });
  }

  receiveSelectedValue(event: any) {
    this.userForm.patchValue({
      [event.controlName]: event.value
    });
    if (event.controlName === 'organizationId') {
      this.getAdvocates(event.value);
    }
  }
}
