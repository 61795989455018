import { Component, Input, OnInit } from '@angular/core';
import { MENU } from 'src/app/constants';
import { NightwebUtil } from 'src/app/utils';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  menus = MENU;
  accessibleMenus: any[] = [];

  @Input() sidenav: any;

  constructor(
    private nightwebUtil: NightwebUtil
  ) { }

  ngOnInit(): void {
    this.accessibleMenus = this.menus.filter((menu: any) => {
      if (this.nightwebUtil.isAccessible(menu.resourceName) && menu.resourceName !== 'Notifications') {
        return menu;
      }
    });
  }

}
