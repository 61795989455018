<nav class="navbar navbar-expand-lg navbar-light bg-white">
    <!-- Container wrapper -->
    <div class="container-fluid justify-content-between">

        <nav aria-label="breadcrumb">
            <ol class="breadcrumb ps-1">
                <li *ngFor="let head of header;first as isFirst" class="breadcrumb-item">
                    <a [routerLink]="(isFirst && header.length>1) ? [head.route]:[]" [class.active]="isFirst && header.length>1">
                        {{head.label}}
                    </a>
                </li>
            </ol>
        </nav>

        <ul class="navbar-nav mb-2 mb-lg-0 d-flex align-items-center gap-2">
            <li *ngIf="isAdvocate">
                <div mdbDropdown class="dropdown" [closeOnOutsideClick]="!datepickerSelected && !timepickerSelected"><!--[closeOnOutsideClick]="false"-->
                    <a (click)="notificationsComponent.onFilterSelected(filterSelected ?? notificationsComponent.filters[0])" data-cy="navbarDropdownMenuLink" aria-expanded="false" role="button" mdbDropdownToggle>
                        <div class="position-relative">
                            <span><i class="fas fa-bell" style="font-size: 20px;"></i></span>
                            <span class="badge-notification" *ngIf="unreadNotificationsCount" style="top: -6px; left: 12px;">{{ unreadNotificationsCount }}</span>
                        </div>
                    </a>
                    <ul
                      mdbDropdownMenu
                      class="dropdown-menu dropdown-menu-end border-0 pt-2 position-relative"
                      aria-labelledby="dropdownMenuButton"
                      style="width: 525px;"
                    >
                        <div class="d-flex justify-content-between ps-2 mb-2">
                            <p class="fw-bold mb-0 me-1">Notifications</p>
                            <app-select *ngIf="notificationsComponent"
                                [form]="notificationsComponent.notificationsForm"
                                [data]="notificationsComponent.filters"
                                [control]="notificationsComponent.notificationsForm.controls['filter']" 
                                [controlName]="'filter'" 
                                [name]="'label'" 
                                [value]="'value'"
                                [width]="'220px'" 
                                (emitSelectedValue)="onFilterSelected($event)" 
                                class="me-2 select-outline"
                                >
                            </app-select>
                        </div>
                        <app-notifications (onPickerSelected)="onPickerSelected($event)" [isNotificationPage]="false" [maxHeight]="'500px'" width="100%" [from]="'dropDown'"></app-notifications>
                        <div class="position-absolute bottom-0 d-flex justify-content-center py-1 w-100" style="background-color: #FFFFFF;">
                            <a [routerLink]="['/notifications']"><p class="dropdown-item text-primary mb-0" style="background: none; padding: 0; font-size: 12px">View All</p></a>
                        </div>
                    </ul>
                </div>
            </li>
            <li mdbDropdown class="nav-item dropdown">
                <a class="nav-link dropdown-toggle d-flex align-items-center gap-1 py-0" data-cy="navbarDropdownMenuLink" role="button" mdbDropdownToggle
                    aria-expanded="false">
                    <span data-cy="navbarDropdownMenuLink" class=" avatar bg-info text-center text-white">
                       <i-bs name="person-fill"></i-bs>
                    </span>
                    <span class="d-flex flex-column align-items-start">
                        <p class="mb-0">{{loggedInUser?.name}} </p>
                        <!-- <i-bs name="chevron-down"></i-bs> -->
                        <span class="px-1 me-1 badge bg-info text-white">{{loggedInUser?.organization?.role?.name}}</span>
                    </span>
                </a>

                <ul mdbDropdownMenu class="dropdown-menu dropdown-menu-end border-0"
                    aria-labelledby="navbarDropdownMenuLink">
                    <li class="dropdown-header" *ngIf="loggedInUser?.organization?.role?.code !== 'SUPER_ADMIN'">
                        <span class="px-1 badge bg-primary">{{loggedInUser?.organization?.name}}</span>
                    </li>
                    <li  *ngIf="loggedInUser?.organization?.role?.code !== 'SUPER_ADMIN'"><hr class="dropdown-divider" /></li>
                    <li>
                        <a class="dropdown-item" role="button" [routerLink]="['/profile']"> My Profile</a>
                    </li>
                    <li>
                        <a class="dropdown-item" (click)="logout()" role="button">Logout</a>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</nav>
