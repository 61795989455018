<!-- Sidenav -->
<!-- sidenav-link  -->
<ul id="scroll-container" class="sidenav-menu p-0">
    <mdb-sidenav-item *ngFor="let menu of accessibleMenus;">
        <a *ngIf="menu?.label" class="sidenav-link" [ngClass]="sidenav.slimCollapsed ? 'p-3' :'p-2'" tabindex="0" role="button" [routerLink]="[menu?.route]" [routerLinkActive]="'active'">
            <i-bs [name]="menu?.icon" class="me-2"></i-bs><span>{{menu?.label}}</span></a>
    </mdb-sidenav-item>
    <!-- <mdb-sidenav-item>
        <a class="sidenav-link" tabindex="0" role="button" [routerLink]="['/end-users']" [routerLinkActive]="'active'">
            <i-bs name="person-fill" class="me-2"></i-bs><span>End-Users</span></a>
    </mdb-sidenav-item>
    <mdb-sidenav-item>
        <a class="sidenav-link" tabindex="0" role="button" [routerLink]="['/organizations']" [routerLinkActive]="'active'">
            <i-bs name="diagram-3-fill" class="me-2"></i-bs><span>Organizations</span></a>
    </mdb-sidenav-item>
    <mdb-sidenav-item>
        <a class="sidenav-link" tabindex="0" role="button" [routerLink]="['/administrators']" [routerLinkActive]="'active'">
            <i-bs name="person-fill" class="me-2"></i-bs><span>Administrators</span></a>
    </mdb-sidenav-item> -->
    <!-- <mdb-sidenav-item>
        <a class="sidenav-link" tabindex="0" role="button" [routerLink]="['/roles-permissions']" [routerLinkActive]="'active'">
            <i-bs name="person-plus" class="me-2"></i-bs><span>Roles & Permissions</span></a>
    </mdb-sidenav-item>
    <mdb-sidenav-item>
        <a class="sidenav-link" tabindex="0" role="button" [routerLink]="['/emergency-states']" [routerLinkActive]="'active'">
            <i-bs name="stack" class="me-2"></i-bs><span>Emergency States</span></a>
    </mdb-sidenav-item>
    <mdb-sidenav-item>
        <a class="sidenav-link" tabindex="0" role="button" [routerLink]="['/activity-log']" [routerLinkActive]="'active'">
            <i-bs name="stack" class="me-2"></i-bs><span>Activity Log</span></a>
    </mdb-sidenav-item> -->
</ul>

<!-- Sidenav -->