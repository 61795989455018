import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-google-map',
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.scss']
})
export class GoogleMapComponent implements OnInit {

  @Input() width: string = '100%';
  @Input() height!: string;
  @Input() features: any;
  @Input() customMarkers: any;

  markers: any[] = [];
  center: any = {lat: 0, lng: 0}; 
  zoom = 13;
  vertices: google.maps.LatLngLiteral[] = [];
  polylineOptions={
    geodesic: true,
    strokeColor: "#3cb371",
    strokeOpacity: 0.7,
    strokeWeight: 4,
  };
  iconPath: string = '../../../../assets/img/';
 
  ngOnInit() {
    this.addMarkers();
  }

  addMarkers() {
    const scaledSize = new google.maps.Size(20, 30);
    if(this.features.count){
      this.vertices = this.features.rows.map((row: any) => row.gps.latitude && row.gps.latitude !== 'null' && row.gps.longitude && row.gps.longitude !== 'null' 
        && ({ lat: parseFloat(row.gps.latitude), lng: parseFloat(row.gps.longitude) }));
      this.vertices = this.vertices.filter((vertice: any) => vertice);
      const locations: Array<any> = [];
      this.features.rows.forEach((row: any) => {
        row.gps.latitude && row.gps.latitude !== 'null' && row.gps.longitude && row.gps.longitude !== 'null' 
        && locations.push({
            position: new google.maps.LatLng(parseFloat(row.gps.latitude), parseFloat(row.gps.longitude)),
          })
      });
      const markedPoints = {
        url: `${this.iconPath}svg/green-circle-icon.svg`,
        anchor: new google.maps.Point(6, 6),
        scaledSize: new google.maps.Size(11, 11)
      };
      locations.forEach(feature => {
        this.markers.push(new google.maps.Marker({ 
          position: feature.position, 
          icon: markedPoints,
         }));
      });
      if(this.markers.length){
        this.markers[0].icon = {
          url: `${this.iconPath}png/blue-marker.png`,
          scaledSize,
        };
        this.markers[this.markers.length - 1].icon = {
          url: `${this.iconPath}png/red-marker.png`,
          scaledSize,
        };
        if(this.customMarkers.indexes.length){
          this.customMarkers.indexes.forEach((index: number) => {
            this.markers[index].icon = {
              url: this.customMarkers.icon,
              scaledSize,
            }
          })
        };
        const firstLat = this.features.rows.find((row: any) => row.gps.latitude).gps.latitude;
        const firstLng = this.features.rows.find((row: any) => row.gps.longitude).gps.longitude;
        this.center =  {
          lat: parseFloat(this.features.rows.find((row: any) => row.isEmergencyState)?.gps.latitude ?? firstLat),
          lng: parseFloat(this.features.rows.find((row: any) => row.isEmergencyState)?.gps.longitude ?? firstLng),
        };
      };
    }
  }
}