export const USER_STATUS = {
    NEW: 'new',
    ACTIVATED: 'activated',
    IN_PROGRESS: 'in progress',
    ACTIVATION_IN_PROGRESS: 'activation in progress',
    DEACTIVATED: 'deactivated'
}

export const END_USER_STATUS = [
    {
        "name": "New",
        "value": "New",
    },
    {
        "name": "Activated",
        "value": "Activated",
    },
    {
        "name": "In Progress",
        "value": "In progress",
    }
];