import { DatePipe } from "@angular/common";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";
import { NIGHTWEB_APP_CONSTANT } from "../constants";
import { Address } from "../models";
import { FormatPhone } from "./formatPhone.util";

const jwtHelper = new JwtHelperService();

@Injectable()

export class NightwebUtil {
    defaultDatePipeFormat: string = NIGHTWEB_APP_CONSTANT.defaultDatePipeFormat;
    defaultDateTimeFormat: string = NIGHTWEB_APP_CONSTANT.shortDateTimeFormat;
    constructor(
        private router: Router,
        private datePipe: DatePipe,
        private formatPhone: FormatPhone
    ) { }

    public getLoggedInUser() {
        const loggedInUser = localStorage.getItem('loggedInUser')
        return loggedInUser && loggedInUser !== "undefined" ? JSON.parse(loggedInUser as any) : null;
    }

    public setLoggedInUser(loggedInUser: string) {
        localStorage.setItem('loggedInUser', JSON.stringify(loggedInUser));
    }

    public removeLoggedInUser() {
        localStorage.removeItem('loggedInUser');
    }

    public setItem(data: any, key: string) {
        localStorage.setItem(key, JSON.stringify(data));
    }

    public getItem(key: string) {
        return localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key) as any) : null;
    }

    public clearStorage() {
        localStorage.clear();
    }

    public parseNumber(number: any): number {
        return number ? parseInt(number) : 0;
    }

    public shallowCopy(data: any) {
        return JSON.parse(JSON.stringify(data));
    }

    public getInitials({ firstName, lastName }: any) {
        if (firstName && lastName) {
            return firstName[0].toUpperCase() + lastName[0].toUpperCase();
        } else if (firstName && !lastName) {
            return firstName[0].toUpperCase();
        }
        return '';
    }

    public decodeToken(token: string) {
        return jwtHelper.decodeToken(token);
    }

    public getFilterParams(params?: any) {
        let filter = {};
        for (const [key, value] of Object.entries(params)) {
            if (value) {
                Object.assign(filter, { [key]: value })
            }
        }
        return filter;
    }

    public formatAddress(address: Address) {
        if (address) {
            const { addressLine1, addressLine2, city, state, zip } = address;
            return `
                ${addressLine1} ${addressLine2 ?? ''} <br />
                ${city ? city + ', ' : ''} ${state} ${zip}
            `
        }
        return '';
    }

    formatContact(contact: any) {
        if (contact) {
            const { phone, email } = contact;
            if (contact.phone) {
                return this.formatPhone.phoneNumber(phone)?.toString() +
                    '<br />' +
                    email
            }
            return email;
        } else {
            return '';
        }
    }

    formatLinkedDevice(device: any) {
        if (device) {
            const { associatedPhone, imeiNumber } = device;
            if (imeiNumber) {
                return imeiNumber +
                    '<br />' +
                    `${associatedPhone.length === 10 ? this.formatPhone.phoneNumber(associatedPhone)?.toString() : associatedPhone}`
            }
            return associatedPhone;
        } else {
            return '';
        }
    }

    formatUpdatedBy(updatedBy: any) {
        if (updatedBy) {
            const { updatedAt, name } = updatedBy;
            if (updatedAt && name) {
                return this.datePipe.transform(updatedAt, this.defaultDatePipeFormat) +
                    '<br />' +
                    name
            } else if (updatedAt) {
                return this.datePipe.transform(updatedAt, this.defaultDatePipeFormat);
            }
            return name;
        } else {
            return '';
        }
    }

    formatCreatedBy(createdBy: any) {
        if (createdBy) {
            const { createdAt, name } = createdBy;
            if (typeof createdBy === 'string') {
                return this.datePipe.transform(createdBy, this.defaultDateTimeFormat);
            };
            if (createdAt && name) {
                return this.datePipe.transform(createdAt, this.defaultDateTimeFormat) +
                    '<br />' +
                    name
            } else if (createdAt) {
                return this.datePipe.transform(createdAt, this.defaultDateTimeFormat);
            };
            return name;
        } else {
            return '';
        }
    }

    formatModule(log: any) {
        const { module, subModule } = log;
        let result = subModule ? `<span class="fw-bold">${subModule}</span><br />` : '';
        if (module) {
            result += `<span class="fs-1">${module}</span>`;
        }
        return result;
    }

    formatEventType(evenType: any) {
        const { type, subType } = evenType;
        if (!subType) {
            return type;
        }
        return `<span class="fw-bold">${type}</span>` + '<br />' + `<span class="fs-1">${subType}</span>`;
    }

    getDisplayName(demographic: any) {
        const { firstName, middleName, lastName } = demographic;
        return (firstName ?? '') + ' ' + (middleName ?? '') + ' ' + (lastName ?? '');
    }

    getDemographic(value: any, field: any) {
        const { displayName, displayOrganization, status } = value;
        let result = `<span>${displayName}</span><br />`;
        if (displayOrganization ?? status) {
            switch (field.subModuleType) {
                case 'CHIP':
                    return result += displayOrganization ? `<span class="badge badge-info fs-1">${displayOrganization}</span>` : `<span class="badge badge-danger fs-1">${status}</span>`;
                default:
                    return result += `<span class="fs-1">${displayOrganization}</span>`
            }
        }
        return result;
    }

    getValue(value: any, key: string) {
        if (Array.isArray(value)) {
            if (value?.length > 0) {
                return value[0]?.[key]
            }
            return '';
        } else {
            if (value)
                return value[key];
            return '';
        }
    }

    isAccessible(moduleName: string, accessType: string = 'R') {
        const loggedInUser = this.getLoggedInUser();
        if (loggedInUser) {
            const rolePermissions = this.decodeToken(loggedInUser)?.organization?.role;
            if (rolePermissions?.code === NIGHTWEB_APP_CONSTANT.roles.SUPER_ADMIN)
                return true;
            if (rolePermissions?.code === NIGHTWEB_APP_CONSTANT.roles.ADVOCATE) {
                rolePermissions.permissions = rolePermissions.permissions.filter((permission: any) => permission.resourceName !== "Organizations");
            };
            if (rolePermissions?.permissions?.length) {
                const data = rolePermissions.permissions.find((permission: any) => permission?.resourceName === moduleName);
                if (data) {
                    switch (accessType) {
                        case 'R':
                            return (data.accessType === 'R' || data.accessType === 'W' || data.accessType === 'R/W');
                        case 'W':
                            return (data.accessType === 'W' || data.accessType === 'R/W');
                        default:
                            break;
                    }
                }
            };
        }
        return false;
    }

    isSuperAdmin() {
        const loggedInUser = this.getLoggedInUser();
        if (loggedInUser) {
            const rolePermissions = this.decodeToken(loggedInUser)?.organization?.role;
            if (rolePermissions?.code === "SUPER_ADMIN")
                return true;
        }
        return false;
    }

    getRole(): string {
        const loggedInUser = this.getLoggedInUser();
        if (loggedInUser) {
            const rolePermissions = this.decodeToken(loggedInUser)?.organization?.role;
            return rolePermissions?.code;
        }
        return '';
    }

    toTitleCase(value: string) {
        value = value ? value[0].toUpperCase() + value.slice(1) : '';
        return value;
    }

    checkScreenSize() {
        return window.innerWidth < 1300; 
    }
}