<div class="d-flex justify-content-center">
    <div [style.width]="width">
            <div class="d-flex px-2 mb-3" *ngIf="isNotificationPage">
                <small class="pt-1">Filter</small>
                <div class="d-flex flex-wrap">
                    <small 
                        *ngFor="let filter of filters; let i = index" 
                        class="bg-gray-light mx-1 py-1 px-2 mb-1" [style.display]="'block'"
                        [class.bg-gray-lighter]="from === 'dropDown'"
                        style="border-radius: 50px; cursor: pointer" 
                        [class]="filter.isSelected && 'text-primary'"
                        (click)="onFilterSelected(filter)"
                        >
                        {{ filter.label }}
                    </small>
                </div>
            </div>
        <div *ngIf="isLoading.notifications" class="pt-3 pb-4">
            <app-loader [loader]="isLoading.notifications"></app-loader>
        </div>
        <div #notificationList (scroll)="onScroll($event)" class="scrollbar-primary thin overflow-auto" [style.max-height]="maxHeight" *ngIf="!isLoading.notifications && notifications.count; else noNotifications">
            <div *ngIf="notifications.todayNotifications?.length">
                <div class="d-flex align-items-center justify-content-between pe-1">
                    <small class="px-2">Today</small>
                    <a *ngIf="notifications.unreadCount" (click)="onMarkAllAsRead($event)"><p class="dropdown-item text-primary mb-0" style="background: none; padding: 0; font-size: 12px">Mark all as Read</p></a>
                </div>
                <li 
                    class="dropdown-item p-0 pe-1" 
                    *ngFor="let notification of notifications.todayNotifications; let i=index" 
                    [class.border-bottom]="notifications.todayNotifications.length - 1 !== i"
                    [class.bg-gray-light]="notification.status === 'new'"
                    >
                    <div class="py-1" (click)="onNotificationSelected(notification, 'todayNotifications')">
                        <div class="row p-0 m-0">
                            <div class="col-1 text-end">
                                <small><i class="fas fa-circle" [style.color]="notification.currentState"></i></small>
                            </div>
                            <div class="col-8 p-0">
                                <p class="mb-0">{{ notification.notice.subject }}</p>
                                <small class="mb-0 text-break">{{ notification.notice.text }}</small><br />
                            </div>
                            <div class="col-3 text-end p-0">
                                <small class="text-muted">{{ notification.createdAt | date: shortDateTimeFormat }}</small>
                            </div>
                        </div>
                        <ng-container *ngTemplateOutlet="followUp; context: {index: i, type: 'todayNotifications', notification: notification}"></ng-container>
                    </div>
                </li>
            </div>
            <div *ngIf="notifications.yesterdayNotifications?.length" [class.mt-3]="notifications.todayNotifications?.length">
                <div class="d-flex align-items-center justify-content-between pe-1">
                    <small class="px-2">Yesterday</small>
                    <a *ngIf="notifications.unreadCount && !notifications.todayNotifications?.length" (click)="onMarkAllAsRead($event)"><p class="dropdown-item text-primary mb-0" style="background: none; padding: 0; font-size: 12px">Mark all as Read</p></a>
                </div>
                <li 
                    class="dropdown-item p-0 pe-1" 
                    *ngFor="let notification of notifications.yesterdayNotifications; let i=index" 
                    [class.border-bottom]="notifications.yesterdayNotifications.length - 1 !== i"
                    [class.bg-gray-light]="notification.status === 'new'"
                    >
                    <div class="py-1" (click)="onNotificationSelected(notification, 'yesterdayNotifications')">
                        <div class="row p-0 m-0">
                            <div class="col-1 text-end">
                                <small><i class="fas fa-circle" [style.color]="notification.currentState"></i></small>
                            </div>
                            <div class="col-8 p-0">
                                <p class="mb-0">{{ notification.notice.subject }}</p>
                                <small class="mb-0 text-break">{{ notification.notice.text }}</small><br />
                            </div>
                            <div class="col-3 text-end p-0">
                                <small class="text-muted">{{ notification.createdAt | date: shortDateTimeFormat }}</small>
                            </div>
                        </div>
                        <ng-container *ngTemplateOutlet="followUp; context: {index: i, type: 'yesterdayNotifications', notification: notification}"></ng-container>
                    </div>
                </li>
            </div>
            <div *ngIf="notifications.olderNotifications?.length" [class.mt-3]="(notifications.todayNotifications?.length || notifications.yesterdayNotifications?.length)">
                <div class="d-flex align-items-center justify-content-between pe-1">
                    <small class="px-2">Older</small>
                    <a *ngIf="notifications.unreadCount && !notifications.todayNotifications?.length && !notifications.yesterdayNotifications?.length" (click)="onMarkAllAsRead($event)"><p class="dropdown-item text-primary mb-0" style="background: none; padding: 0; font-size: 12px">Mark all as Read</p></a>
                </div>
                <li 
                    class="dropdown-item p-0 pe-1" 
                    *ngFor="let notification of notifications.olderNotifications; let i=index" 
                    [class.border-bottom]="notifications.olderNotifications.length - 1 !== i" 
                    [class.bg-gray-light]="notification.status === 'new'"
                    >
                    <div class="py-1" (click)="onNotificationSelected(notification, 'olderNotifications')">
                        <div class="row p-0 m-0">
                            <div class="col-1 text-end">
                                <small><i class="fas fa-circle" [style.color]="notification.currentState"></i></small>
                            </div>
                            <div class="col-8 p-0">
                                <p class="mb-0">{{ notification.notice.subject }}</p>
                                <small class="mb-0 text-break">{{ notification.notice.text }}</small><br />
                            </div>
                            <div class="col-3 text-end p-0">
                                <small class="text-muted">{{ notification.createdAt | date: shortDateTimeFormat }}</small>
                            </div>
                        </div>
                        <ng-container *ngTemplateOutlet="followUp; context: {index: i, type: 'olderNotifications', notification: notification}"></ng-container>
                    </div>
                </li>
            </div>
            <ng-template #followUp let-index="index" let-type="type" let-notification="notification">
                <div class="row p-0 m-0">
                    <div class="col-1"></div>
                    <div class="col-11 p-0">
                        <div class="mt-1">
                            <div class="d-flex" *ngIf="!notification.addFollowup && notification.status !== 'followupRequired' && notification.status !== 'resolved' && !notification.clearing">
                                <small class="me-2 text-muted" (click)="onFollowupActionsTaken($event, index, type, 'clear')">Clear</small>
                                <small class="text-primary" (click)="onFollowupActionsTaken($event, index, type, 'followup')">Follow-up</small>
                            </div>
                            <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" *ngIf="notification.clearing"></span>
                            <small *ngIf="notification.status === 'followupRequired'" class="text-primary mt-1" (click)="onFollowupActionsTaken($event, index, type, 'edit')">Follow-up made by {{ notification.followUpDetails.by }} on {{ notification.followUpDetails.updatedAt | date: shortDateTimeFormat }}</small>
                            <small *ngIf="notification.status === 'resolved'" class="text-muted mt-1">Notification cleared by {{ notification.updatedBy.name }} on {{ notification.updatedAt | date: shortDateTimeFormat }}</small>
                        </div>
                        <div *ngIf="notification.addFollowup" (click)="$event.stopPropagation()">
                            <hr />
                            <small class="fw-bold">Follow-up Reminder</small>
                            <div class="mt-1">
                                <small>Set Follow-up Date*</small>
                                <div class="my-1">
                                    <div class="row p-0 m-0 gx-5">
                                        <div class="col-6 ps-0 pe-1">
                                            <mdb-form-control>
                                                <div class="datepicker-wrapper">
                                                    <input
                                                        mdbInput
                                                        [mdbDatepicker]="datepicker"
                                                        type="text"
                                                        class="form-control bg-gray-lighter"
                                                        data-cy="date-picker"
                                                        [ngModel]="notifications[type][index]?.followUpDetails?.date || tomorrowDate"
                                                        (ngModelChange)="onInputChange($event, type, 'date', index)"
                                                        [readonly]="true"
                                                    />
                                                    <mdb-datepicker-toggle [mdbDatepicker]="datepicker"></mdb-datepicker-toggle>
                                                    <mdb-datepicker 
                                                        #datepicker 
                                                        [minDate]="todayDate" 
                                                        (opened)="onPickerSelected.emit({ type: 'date' })" 
                                                        (closed)="onPickerSelected.emit({ type: 'date', isSelected: true })" 
                                                        (dateChanged)="onPickerSelected.emit({ type: 'date', isSelected: true })" 
                                                        [inline]="true" 
                                                        [format]="mdbDateFormat"
                                                        ></mdb-datepicker>
                                                </div>
                                            </mdb-form-control>
                                        </div>
                                        <div class="col-6 ps-1 pe-0">
                                            <mdb-form-control>
                                                <div class="datepicker-wrapper">
                                                    <input
                                                        mdbInput
                                                        type="text"
                                                        data-cy="time-picker"
                                                        class="form-control bg-gray-lighter"
                                                        [mdbTimepicker]="timepicker"
                                                        [ngModel]="notifications[type][index]?.followUpDetails?.time || this.defaultTime"
                                                        (ngModelChange)="onInputChange($event, type, 'time', index)"
                                                        [readonly]="true"
                                                    />
                                                    <mdb-timepicker-toggle [mdbTimepickerToggle]="timepicker"></mdb-timepicker-toggle>
                                                    <mdb-timepicker 
                                                        #timepicker 
                                                        [inline]="true" 
                                                        (opened)="onPickerSelected.emit({ type: 'time' })"
                                                        (closed)="onPickerSelected.emit({ type: 'time', isSelected: true })"
                                                        (timeChange)="onPickerSelected.emit({ type: 'date', isSelected: true })"
                                                        ></mdb-timepicker>
                                                </div>    
                                            </mdb-form-control>
                                        </div>
                                    </div>
                                </div>
                                <small>Note*</small>
                                <textarea maxlength="200" [ngModel]="notifications[type][index]?.followUpDetails?.note" (ngModelChange)="onInputChange($event, type, 'note', index)" [readonly]="notification.savingFollowUp"></textarea>
                                <div class="d-flex justify-content-between my-1">
                                    <button type="button" class="btn btn-gray-light btn-sm" (click)="onFollowupActionsTaken($event, index, type, 'cancel')" [class.disabled]="notification.savingFollowUp">Cancel</button>
                                    <button 
                                        type="button" 
                                        class="btn btn-primary btn-sm d-flex align-items-center" 
                                        (click)="onFollowupActionsTaken($event, index, type, 'save', notification)" 
                                        [class.disabled]="notification.savingFollowUp || !tempNotificationStorage[type][index]?.followUpDetails?.note">
                                        <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" *ngIf="notification.savingFollowUp"></span>
                                            &nbsp;{{ notification.followUpDetails.createdAt ? 'Update' : 'Save' }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
        <ng-template #noNotifications>
            <div *ngIf="!isLoading.notifications" class="text-center py-2 mb-3">
                <i class="fas fa-bell fa-2x text-muted my-1"></i><br />
                <small>No notifications yet</small>
            </div>
        </ng-template>
    </div>
</div>