import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { API_CONSTANTS } from '../constants';
import { HttpClientService } from './http-client.service';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  constructor(
    private http: HttpClientService
  ) { }

  getAllDevices(query?: any) {
    return this.http.get(API_CONSTANTS.DEVICE, query)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  getById(deviceId: string) {
    return this.http.get(API_CONSTANTS.DEVICE + `/${deviceId}`)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  create(device: any) {
    return this.http.post(API_CONSTANTS.DEVICE, device)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  update(device: any) {
    return this.http.put(API_CONSTANTS.DEVICE + `/${device.id}`, device)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  delete(deviceId: string) {
    return this.http.remove(API_CONSTANTS.DEVICE + `/${deviceId}`)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  pingGps(id: string) {
    return this.http.post(`${API_CONSTANTS.DEVICE}/ping-gps/${id}`,'')
    .pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  getDeviceGps(id: string) {
    return this.http.get(`${API_CONSTANTS.DEVICE}/get-gps-statistics/${id}`)
    .pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  pingNetwork(id: string) {
    return this.http.post(`${API_CONSTANTS.DEVICE}/ping-internet/${id}`,'')
    .pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  getDeviceNetwork(id: string) {
    return this.http.get(`${API_CONSTANTS.DEVICE}/get-internet-statistics/${id}`)
    .pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  getWatchStatistics(deviceId: string) {
    return this.http.get(`${API_CONSTANTS.DEVICE}/statistics`, { deviceId })
    .pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  getRRMSLogs(accountNumber: string) {
    return this.http.get(`/end-user-crisis/${accountNumber}`)
    .pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  clearEmergency(body: any) {
    const token = 'RWSRzv28pz7HjWdvX2DpvdW9THyEbRWJq';
    const headers = new HttpHeaders({
      'rapid-access-token': token
    });
   
    return this.http.postWithHeaders(`/end-user-crisis`, body, headers)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }
}
