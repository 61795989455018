import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CodeInputComponent } from 'angular-code-input';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { finalize } from 'rxjs/operators';
import { MESSAGE_CONSTANTS, NIGHTWEB_APP_CONSTANT, STATUS_CODES } from 'src/app/constants';
import { AuthService, ToasterService } from '../../../services';
import { ClientJS } from 'clientjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NightwebUtil } from 'src/app/utils';

@Component({
  selector: 'app-verification-pin-modal',
  templateUrl: './verification-pin-modal.component.html',
  styleUrls: ['./verification-pin-modal.component.scss']
})
export class VerificationPinModalComponent implements OnInit {
  @ViewChild('codeInput', { static: true }) codeInput!: CodeInputComponent;
  userName!: string;
  formData!: any;
  code: string = '';
  loading: boolean = false;
  submitted: boolean = false;
  verificationCodeSent: boolean = false;
  timeValidity: number = 300; // 5 min
  codeExpired: boolean = false;
  verificationForm!: FormGroup;
  browserFingerprint!: any;
  status_codes = STATUS_CODES;
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private nightwebUtil: NightwebUtil,
    private toasterService: ToasterService,
    public modalRef: MdbModalRef<VerificationPinModalComponent>
  ) {
    this.browserFingerprint = new ClientJS().getFingerprint();
  }

  ngOnInit(): void {
    if (this.formData?.userName) {
      this.userName = this.formData.userName;
    }
    this.setMFAForm();
  }

  setMFAForm() {
    this.verificationForm = this.formBuilder.group({
      verificationCode: ['', Validators.required],
      email: [this.userName, [Validators.required, Validators.pattern(NIGHTWEB_APP_CONSTANT.emailPattern)]],
      browserFingerprint: [this.browserFingerprint.toString(), Validators.required],
      rememberPassword: [this.formData?.rememberPassword],
      rememberMFACode: [false]
    })
  }

  // this called every time when user changed the code
  onCodeChanged(code: string) {
    this.code = code;
    this.verificationForm.controls['verificationCode']?.setValue(this.code);
  }

  // this called only if user entered full code
  onCodeCompleted(code: any) {
    this.verify();
  }

  cancel() {
    this.modalRef.close();
  }

  verify() {
    if (this.code && this.verificationForm.valid) {
      this.loading = true;
      this.authService.verifyLoginCode(this.verificationForm.value)
        .pipe(finalize(() => {
          this.codeExpired = false;
          this.loading = false;
        }
        ))
        .subscribe({
          next: (response) => {
            if (response.statusCode == this.status_codes.OK) {
              // this.nightwebUtil.setLoggedInUser(response.data.token);
              if (response?.data?.authTokenDetails?.token) {
                this.nightwebUtil.setLoggedInUser(response.data.authTokenDetails.token);
                this.modalRef.close(response.data);
                this.router.navigate(['/end-users']);
              }
            }
          },
          error: (e) => {
          }
        })
    } else {
      this.toasterService.show("Warning", MESSAGE_CONSTANTS.NO_CODE);
    }
  }

  resendVerificationCode() {
    this.authService.resendLoginCode(this.userName)
      .pipe(finalize(() => this.codeExpired = false))
      .subscribe({
        next: (response) => {
          if (response.statusCode == this.status_codes.OK) {
            this.toasterService.show("Success", response?.message);
          }
        },
        error: (e) => {
        }
      })
  }
}
