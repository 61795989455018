<form [formGroup]="migrateEndUsersForm" autocomplete="off">
    <div class="d-flex justify-content-between">
        <div class="d-flex justify-content-start">
            <app-search (emitSearchKeyword)="receiveSearchKeyword($event)"></app-search>
        </div>
        <div class="d-flex justify-content-end align-items-center">
            <app-select [form]="migrateEndUsersForm" [control]="migrateEndUsersForm.controls['organizationId']" [controlName]="'organizationId'" [clearButton]="true"
                [disabled]="!organizations.length" [filter]="true" [data]="organizations" [name]="'label'" [value]="'value'"
                [placeholder]="organizations.length ? 'Select Organization' : 'Loading...'" [width]="'260px'" (emitSelectedValue)="receiveSelectedValue($event)" class="me-2 select-outline"></app-select>
            <app-select [form]="migrateEndUsersForm" [control]="migrateEndUsersForm.controls['advocateId']" [controlName]="'advocateId'" [clearButton]="true"
                [disabled]="!advocates.length" [filter]="true" [data]="advocates" [name]="'label'" [value]="'value'"
                [placeholder]="advocates.length ? 'Select Advocate' : 'Loading...'" [width]="'190px'" (emitSelectedValue)="receiveSelectedValue($event)" class="me-2 select-outline"></app-select>
            <app-select [form]="migrateEndUsersForm" [control]="migrateEndUsersForm.controls['status']" [controlName]="'status'"
                [data]="statuses" [name]="'label'" [value]="'value'"
                [width]="'180px'" (emitSelectedValue)="receiveSelectedValue($event)" class="me-2 select-outline"></app-select>
            <button type="button" class="btn btn-primary" [class.disabled]="!selectedUsers.length || isLoading.migration" (click)="openConfirmationModal()">
                <i class="fas fa-exchange-alt"></i> Migrate
            </button>
        </div>
    </div>
</form>

<div class="mt-3">
    <app-table [tableConfig]="tableConfig" [loading]="isLoading.tableData" (onScrollToBottom)="scrollToBottom($event)" [isProcessing]="isLoading.migration"
        [notFoundMessage]="notFoundMessage" (sendEvent)="receiveEvent($event)">
    </app-table>
</div>