import { Injectable } from '@angular/core';
import { HttpClientService } from './http-client.service';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NightwebService {

  constructor(
    private _http: HttpClientService
  ) { }

  uploadFile(route: string, data: any) {
    const formData = new FormData();
    formData.append('file', data.uploadFile[0]);
    formData.append('organizationId', data.organizationId);
    return this._http.post(`${route}/bulk-add`, formData)
    .pipe(
      map((response: any) => response)
    );
  }
}
