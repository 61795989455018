export interface TableConfig {
    headerElements: Array<string>;
    bodyElements: Array<BodyElements>;
    tableDatas: Array<any>;
    actionItems?: Array<ActionItems>;
}

export interface BodyElements {
    value: string | string[];
    dataType?: string;
    objectKey?: string;
    pipe?: string;
    type?: InputType;
    addonType?: InputType;
    onclick?: (e: any) => void;
    navigation?: boolean;
    actionButton?: boolean;
    actionItemType?: ActionItemType;
    concatString?: string;
    showTooltip?: boolean;
    subModuleType?: string;
}

export interface ActionItems {
    name: string | null;
    icon?: string;
    class?: string;
    eventName?: string;
    eventChecker?: string;
    display?: boolean
}

export enum ActionItemType {
    ANCHOR = 'a',
    BUTTON = 'button'
}

export enum InputType {
    TEXT = 'text',
    PASSWORD = 'password',
    EMAIL = 'email',
    RADIO = 'radio',
    CHECKBOX = 'checkbox',
}