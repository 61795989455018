import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { finalize } from 'rxjs';
import { API_CONSTANTS, MESSAGE_CONSTANTS, STATUS_CODES } from '../../../constants';
import { NightwebService, OrganizationService, ToasterService } from '../../../services';

@Component({
  selector: 'app-upload-modal',
  templateUrl: './upload-modal.component.html',
  styleUrls: ['./upload-modal.component.css']
})
export class UploadModalComponent implements OnInit {
  title!: string;
  uploadForm!: FormGroup;
  isLoading = {
    upload: false,
    organizations: false
  };
  organizations: any[] = [];

  constructor(
    public modalRef: MdbModalRef<UploadModalComponent>,
    private _formBuilder: FormBuilder,
    private _nightwebService: NightwebService,
    private _toasterService: ToasterService,
    private _organizationService: OrganizationService
  ) {
    this.setUploadForm();
   }

  ngOnInit(): void {
    this.getOrganizations();
  }

  setUploadForm() {
    this.uploadForm = this._formBuilder.group({
      organizationId: [null, Validators.required],
      uploadMedia: [null, Validators.required]
    });
  }

  getOrganizations() {
    this._organizationService.getAllOrganizations()
      .pipe()
      .subscribe({
        next: response => {
          if (response.statusCode == STATUS_CODES.OK) {
            this.organizations = response.data.rows.map((organization: any) => ({ ...organization, label: organization.displayName || organization.businessName, value: organization.id }));
          };
        },
        error: error => console.log('error getting organizations', error) 
      })
  }

  onFileAdd(event: any) {
    this.uploadForm.controls['uploadMedia'].setValue(event);
  }

  onFileRemove() {
    this.uploadForm.controls['uploadMedia'].setValue(null);
  }

  onUpload() {
    this.isLoading.upload = true;
    this._nightwebService.uploadFile(API_CONSTANTS.DEVICE, { organizationId: '63be690091bd71a3b833325f', uploadFile: this.uploadForm.get('uploadMedia')?.value })
    .pipe(finalize(() => { this.isLoading.upload = false; }))
    .subscribe({
      next: response => {
        if (response.statusCode == STATUS_CODES.OK) {
          this._toasterService.show('Success', MESSAGE_CONSTANTS.DEVICE_UPLOAD_PROCESSED);
          this.modalRef.close();
        };
      },
      error: error => console.log('error uploading file', error)
    });
  }

  receiveSelectedValue(event: any) {
    if (event) {
      this.uploadForm.patchValue({
        [event.controlName]: event.value
      });
    };
  }
}
