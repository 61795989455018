export enum CONFIGURATION_CONSTANT {
    CHECK_IN_TIME = 'CHECK_IN_TIME',
    HEART_RATE_ENABLED = 'HEART_RATE_ENABLED',
    GPS_ENABLED = 'GPS_ENABLED',
    LTE_SIGNAL_STRENGTH_ENABLED = 'LTE_SIGNAL_STRENGTH_ENABLED',
    SIGNAL_STRENGTH_ENABLED = 'SIGNAL_STRENGTH_ENABLED',
    FALL_DETECTION_ENABLED = 'FALL_DETECTION_ENABLED',
    FALL_DETECTION = 'FALL_DETECTION',
    BATTERY_STATUS_ENABLED = 'BATTERY_STATUS_ENABLED',
    KIOSK_MODE_ENABLED = 'KIOSK_MODE_ENABLED',
    RAPID_RESPONSE_CONTACT_PHONE = 'RAPID_RESPONSE_CONTACT_PHONE',
    RAPID_RESPONSE_CONTACT_URL = 'RAPID_RESPONSE_CONTACT_URL',
    LOW_BATTERY_THRESHOLD = 'LOW_BATTERY_THRESHOLD',
    NO_PACKET_THRESHOLD = 'NO_PACKET_THRESHOLD',
    NOT_WORN_THRESHOLD = 'NOT_WORN_THRESHOLD'
}

export const CONFIGURATION_TYPE_CONSTANT = {
    STRING: 'String',
    UNIT: 'Unit',
    BOOLEAN: 'Boolean',
    NUMBER: 'Number',
    DATE: 'Date',
    DATETIME: 'DateTime',
}