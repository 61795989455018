import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { HttpClientService } from './http-client.service';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  constructor(
    private http: HttpClientService
  ) { }

  getAppVersion() {
    return this.http.get('')
      .pipe(
        map((response: any) => response)
      );
  }
}
