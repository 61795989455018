import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
// import { MdbAutocompleteSelectedEvent } from 'mdb-angular-ui-kit/autocomplete';
import { map, Observable, startWith, Subject, tap } from 'rxjs';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss']
})
export class AutocompleteComponent implements OnInit {

  @Input() form!: FormGroup;
  @Input() data: any[] = [];
  @Input() control: any = '';
  @Input() label: string = '';
  @Input() value: string = '';
  @Input() name: string = '';
  @Input() placeholder: string = 'Search';
  @Input() filterKeys: any[] = [];
  @Output() emitSelectedValue = new EventEmitter<any>();
  searchText = new Subject<string>();
  results!: Observable<any[]>;
  notFound = false;
  result: any;
  highlightedOption: number = 0;

  constructor() {
    this.results = this.searchText.pipe(
      startWith(''),
      map((value: string) => this.filter(value)),
      tap((results: string[]) =>
        this.notFound = results.length === 0
      )
    );
  }

  ngOnInit(): void {
  }
  
  onKeydown(event: KeyboardEvent) {
    if (['Tab', 'ArrowUp', 'ArrowDown'].includes(event.key)) {
      // Handle value change on tab press
      if (event.key === 'Tab') {
        this.handleChange({ option: {value: this.result[this.highlightedOption]} });
      };
  
      // Handle arrow key navigation
      if (['ArrowUp', 'ArrowDown'].includes(event.key)) {
        event.preventDefault();
        if (event.key === 'ArrowUp' && this.highlightedOption > 0) {
          this.highlightedOption--;
        } else if (event.key === 'ArrowDown' && this.highlightedOption < this.result.length - 1) {
          this.highlightedOption++;
        };
      }
    } else {
      this.highlightedOption = 0;
      this.control.value = '';
    };
  }

  getValue(option: any) {
    return option[this.value];
  }

  getOption(option: any) {
    return option[this.name];
  }

  filter(value: any): any[] {
    const filterValue = typeof value === 'string' ? value?.toLowerCase() : value.value.toLowerCase();
    this.result = this.data.filter((item: any) =>
      this.filterKeys.some((key) => {
        return item[key]?.toLowerCase().includes(filterValue);
      })
    );
     return this.result;
  }

  displayValue(option: any): string {
    return option['value'];
  }

  handleChange({ option: { value } }: any) {
    this.searchText.next(value[this.value]);
    this.emitSelectedValue.emit(value[this.value]);
  }
}
