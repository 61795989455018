<div class="d-flex justify-content-between row bg-gray-light py-3" style="margin-top: -2rem;">
    <div class="d-flex">
        <span class="nav-link avatar p-0 bg-primary text-center text-white fw-bold me-3">
            <i-bs name="person-fill"></i-bs>
        </span>
        <div class="d-flex flex-column">
            <ng-container *ngIf="!loading; then isLoaded; else notLoaded"></ng-container>
            <ng-template #isLoaded>
                <h5 class="mb-0">
                    {{
                    (user?.demographic?.firstName ?? '') + ' ' 
                    + (user?.demographic?.middleName ?? '') + ' '
                    + (user?.demographic?.lastName ?? '')
                    }}</h5>
                <span>{{ user?.demographic?.email ?? ''}}</span>
                <div class="mt-1" *ngIf="isAdvocate">
                    <small><b>{{ user?.organizationInfo.businessName || user?.organizationInfo.displayName }}</b></small><br />
                    <small>{{ user?.organizationInfo.address.addressLine1 }} {{ user?.organizationInfo.address.addressLine2 ?? '' }} {{ user?.organizationInfo.address.city }}, {{ user?.organizationInfo.address.state }} {{ user?.organizationInfo.address.zip }}</small>
                </div>
            </ng-template>
            <ng-template #notLoaded>
                <h5 class="mb-0"><app-skeleton-loader [config]="skeletonTheme"></app-skeleton-loader></h5>
                <span><app-skeleton-loader [config]="skeletonTheme"></app-skeleton-loader></span>
            </ng-template>
        </div>
    </div>
</div>
<div class="row custom-tabs">
    <mdb-tabs #profileTab>
        <mdb-tab title="Profile Information">
            <ng-template mdbTabContent>
                <app-profile-information [user]="user" [isAdvocate]="isAdvocate"></app-profile-information>
            </ng-template>
        </mdb-tab>
        <mdb-tab title="Change Password">
            <ng-template mdbTabContent>
                <app-change-password></app-change-password>
            </ng-template>
        </mdb-tab>
    </mdb-tabs>
</div>