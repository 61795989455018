<div class="modal-header shadow-sm">
    <h6 class="modal-title" id="exampleModalLabel">
        {{ title }}
    </h6>
    <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>
</div>
<div class="modal-body scrollbar-primary thin">
    <form [formGroup]="linkDeviceForm" autocomplete="off">
        <div class="row">
            <p>Please select a Device from the list below to link with the End User <strong>{{
                (formData?.demographic?.firstName??'')
                + ' ' +
                (formData?.demographic?.middleName??'')
                + '' +
                (formData?.demographic?.lastName??'')
                }}
                ({{formData?.demographic?.email}})</strong>.</p>
        </div>
        <div class="md-form mb-2">
            <app-search (emitSearchKeyword)="receiveSearchKeyword($event)"
                [placeholder]="'Search Device you want to link'"></app-search>
        </div>
        <div class="md-form mb-2">
            <app-table [tableConfig]="tableConfig" [module]="'Link device module'" [loading]="loading"
                [notFoundMessage]="notFoundMessage" [tableWrapperClass]="'table-responsive'"
                [tableHeadClass]="tableHeadClass" (sendEvent)="receiveEvent($event)"></app-table>
        </div>
    </form>
</div>
<div class="modal-footer pt-0 d-flex justify-content-between">
    <button type="button" class="btn btn-gray-light" (click)="closeModal()">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="setupConfiguration()" [disabled]="!selectedDeviceId">Setup
        Configuration</button>
</div>